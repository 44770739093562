import {format, parse, parseISO} from 'date-fns';
import moment from "moment/moment";

export const BACKEND_FORMAT = 'yyyy-MM-dd';
export const FRONTEND_FORMAT = 'dd/MM/yyyy';
export const BLINKID_FORMAT = 'd/M/yyyy';

export const BE_TIME_FORMAT = 'HH:mm:ss';
export const FE_TIME_FORMAT = 'HH:mm';


const dateFromBackend = (date: string): string => {
    return format(parseISO(date), FRONTEND_FORMAT);
};

const dateToBackend = (date: string): string => {
    const parsedDate =  parse(date, FRONTEND_FORMAT, new Date());
    return format(parsedDate, BACKEND_FORMAT);
};

const showDate = (date: Date|undefined): string|undefined => {
    return date? format(date, FRONTEND_FORMAT):undefined;
};

const showStringDate = (date: string): string => { // works only with dd/MM/yyyy format in input
        return format(parseISO(date), FRONTEND_FORMAT);
};

const showTime = (time: string) => {
    const parsedTime = stringToDate(time, BE_TIME_FORMAT);
    return format(parsedTime, FE_TIME_FORMAT);
};

//helper
const stringToDate = (stringDate: string, format: string): Date => {
    return parse(stringDate, format, new Date());
};

const isDateBeforeYears = (date?: string, years?: number): boolean => {
    if (date && years){
        const parsedDate = new Date(date);
        const result = moment().diff(moment(parsedDate), 'years') >= years
        return result
    }
    return false;
}

const isDateBeforeDays = (date?: string, days?: number): boolean => {
    if (date && days){
        const parsedDate = new Date(date);
        return moment().diff(moment(parsedDate), 'days') >= days
    }
    return false;
}

// check if works before usage
const isDateAfterDays = (date?: string, days?: number): boolean => {
    if (date && days){
        const parsedDate = new Date(date);
        return moment(parsedDate).diff(moment(), 'days') >= days
    }
    return false;
}

const isBeforeToday = (date?: string): boolean => {
    if (date){
        const parsedDate = new Date(date);
        return moment().isBefore(moment(parsedDate), 'days')
    }
    return false;
}

const isAfterToday = (date?: string): boolean => {
    if (date){
        const parsedDate = new Date(date);
        return moment().isAfter(moment(parsedDate), 'days')
    }
    return false;
}

const getToday = (): string => {
    return format(new Date(), FRONTEND_FORMAT);
}

const getTodayDate = (): Date => {
    const stringDate = format(new Date(), FRONTEND_FORMAT);
    return parse(stringDate, FRONTEND_FORMAT, new Date());
}

export const DateManager = {
    dateFromBackend,
    dateToBackend,
    showDate,
    showStringDate,
    showTime,
    isDateBeforeYears,
    isDateBeforeDays,
    isDateAfterDays,
    isBeforeToday,
    isAfterToday,
    getToday,
    stringToDate,
    getTodayDate
}
