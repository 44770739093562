import React, {FC, useEffect, useState} from 'react';
import MyPage from 'shared/components/defaultComponents/myPage/myPage.component';
import enTexts from 'screens/summingUp/i18n/en.json';
import itTexts from 'screens/summingUp/i18n/it.json';
import {loadTranslations} from 'i18n/i18n.service';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {Box, Checkbox, FormControl, FormErrorMessage, FormLabel, Text, VStack} from '@chakra-ui/react';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {SummingUpBillingForm, summingUpBillingFormDefault} from 'screens/summingUp/screens/summingUpBilling/types';
import {billingValidationSchema} from 'screens/summingUp/screens/summingUpBilling/summingUpBilling.validation';
import MyButton from 'shared/components/defaultComponents/myButton/myButton.component';
import {safeArea} from 'style/styleConstants';
import {MenuLanguageComponent} from 'i18n/component/menuLanguage.component';
import {MyFormController} from 'shared/components/defaultComponents/myFormController/myFormController.component';
import {checkinActions} from 'store/checkin/checkin.action';
import {RoutesPaths} from 'navigation/routes';
import {useHistory} from 'react-router-dom';
import {
    Option,
    SelectAutocomplete
} from "../../../../shared/components/selectAutocomplete/selectAutocomplete.component";
import {autocompleteActions} from "../../../../store/autocomplete/autocomplete.action";
import {veriffMapperUtils} from "service/mappers/veriff/verifMapper.utils";

export interface SummingUpBillingProps {
}

export const SummingUpBilling: FC<SummingUpBillingProps> = (props) => {
    const i18nTexts = {
        'en': enTexts,
        'it': itTexts,
    };
    loadTranslations('summingUpPage', i18nTexts)
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const history = useHistory()
    const {isError, isLoading, prenotazione,veriffExtra} = useAppSelector(state => state.checkinReducer);
    const [isPersonaFisica, setIsPersonaFisica] = useState<boolean>(true);
    const [hasCodiceFiscale, setHasCodiceFiscale] = useState<boolean>(true);
    const autocompleteState = useAppSelector(state => state.autocompleteReducer);
    const {states, cities, documents} = autocompleteState
    const [isItalian, setIsItalian] = useState<boolean>(false);

    //Form
    const [formDefaultValues, setFormDefaultValues] = useState<SummingUpBillingForm>(summingUpBillingFormDefault());
    const {
        register,
        control,
        reset,
        setValue,
        handleSubmit,
        formState: {errors, isSubmitting}
    } = useForm<SummingUpBillingForm>({
        resolver: yupResolver(billingValidationSchema),
        defaultValues: formDefaultValues,
    });
    useEffect(() => {

        //Autocomplete states
        if (states.length === 0) {
            dispatch(autocompleteActions.fetchStates())
        }
        handleAutoFill();
    }, []);
    const formSubmit = async (billingForm: SummingUpBillingForm) => {
        console.log(billingForm)
        dispatch(checkinActions.saveBillingData(billingForm))
        return history.push(RoutesPaths.SUMMINGUP_ADDUSER.toString())
    }

    const handleAutoFill = async () => {
        if (prenotazione?.utenteCapoGruppo) {
            const {nome, cognome, comuneDiResidenza, nazioneDiResidenza} = prenotazione.utenteCapoGruppo
            setValue('nome', nome)
            setValue('cognome', cognome)
            setValue('citta', comuneDiResidenza)
            setValue('nazione', nazioneDiResidenza)
            setIsItalian(nazioneDiResidenza === 'ITALIA'); // Update isItalian state

            setValue('codiceFiscale', veriffExtra?.codiceFiscale)
            //veriffExtra?.indirizzo = VIA CALABRIA, S/A SANT'EGIDIO ALLA VIBRATA (TE) split by ,

            let indirizzo = veriffExtra?.indirizzo ? veriffExtra?.indirizzo.split(',') : []
            if (indirizzo.length > 0) {
                setValue('indirizzo', indirizzo[0])
            }
            if (comuneDiResidenza && comuneDiResidenza !== '') {
                try {
                    const cityMetadata = await veriffMapperUtils.fetchCitySearch(comuneDiResidenza);
                    if (cityMetadata && cityMetadata.provincia && cityMetadata.provincia != '') {
                        setValue('provincia', cityMetadata.provincia);
                    }
                } catch (e) {
                    console.log('Error fetching city metadata for provincia: ', e)
                }
            }
        }
    }


    const renderVariants = () => {
        const renderPersonaFisica = () => {
            return (
                <>
                    <MyFormController register={register('nome')}
                                      label={t('common:USER_DATA:NOME')}
                                      errorField={errors.nome}
                    />
                    <MyFormController register={register('cognome')}
                                      label={t('common:USER_DATA:COGNOME')}
                                      errorField={errors.cognome}
                    />

                    <FormControl>
                        <FormLabel color={'titleColor'}>{t('common:USER_DATA:CODICE_FISCALE')}</FormLabel>
                        {!isItalian && (
                        <Box mt={2} mb={1}>

                            <Checkbox
                                isChecked={!hasCodiceFiscale}
                                onChange={() => {
                                    setValue('codiceFiscale', '')
                                    setHasCodiceFiscale(!hasCodiceFiscale)
                                }}
                            >
                                <Text fontSize={'sm'}
                                      textColor={'titleColor'}>{t('summingUpPage:TEXTS:DONT_HAVE_CODICE_FISCALE_SUBTITLE')}
                                </Text>
                            </Checkbox>
                        </Box>
                        )}

                        {hasCodiceFiscale && (
                            <MyFormController register={register('codiceFiscale')}
                                              placeHolder={t('common:USER_DATA:CODICE_FISCALE')}
                                              customOnChange={(e) => e.toUpperCase()}
                                              errorField={errors.codiceFiscale}
                            />
                        )}
                    </FormControl>

                </>
            )
        }

        const renderPersonaGiuridica = () => {
            return (
                <>
                    <MyFormController register={register('partitaIva')}
                                      label={t('common:USER_DATA:PARTITA_IVA')}
                                      errorField={errors.partitaIva}
                    />
                    <MyFormController register={register('sdi')}
                                      label={t('common:USER_DATA:SDI')}
                                      errorField={errors.sdi}
                    />
                    <MyFormController register={register('ragioneSociale')}
                                      label={t('common:USER_DATA:RAGIONE_SOCIALE')}
                                      errorField={errors.ragioneSociale}
                    />
                </>
            )
        }

        if (isPersonaFisica) {
            return renderPersonaFisica()
        }
        return renderPersonaGiuridica()
    }


    return (
        <MyPage
            header={{pageName: t('summingUpPage:TEXTS:ADD_BILLING_TITLE')}}
            isError={isError}
            isLoading={isLoading}
        >
            <Box height={'100%'} width={'100%'} px={3}>
                <form onSubmit={handleSubmit(formSubmit)}>
                    <VStack w={'full'} mt={2}>
                        {/*Start Form */}
                        <FormControl isInvalid={!!errors.personaFisica}>
                            <FormLabel htmlFor={'personaFisica'} color={'titleColor'}>{t('summingUpPage:TEXTS:ADD_BILLING_SUBTITLE')}</FormLabel>
                            <Controller
                                control={control}
                                name="personaFisica"
                                render={({field}) => (
                                    <Checkbox ref={field.ref} onChange={(e) => {
                                        field.onChange(e)
                                        setIsPersonaFisica(!e.target.checked)
                                        const newForm = summingUpBillingFormDefault({personaFisica: !e.target.checked})
                                        setFormDefaultValues(newForm)
                                        reset(newForm)
                                        e.preventDefault();
                                    }} onBlur={field.onBlur}
                                              name={field.name} isChecked={!field.value}>
                                        <Text fontSize={'sm'}
                                              textColor={'titleColor'}>{t('common:USER_DATA:AZIENDA')}</Text>
                                    </Checkbox>
                                )}
                            />
                            <FormErrorMessage>{t(`${errors.personaFisica?.message}`)}</FormErrorMessage>
                        </FormControl>

                        {renderVariants()}

                        <MyFormController register={register('indirizzo')}
                                          label={t('common:USER_DATA:INDIRIZZO_FATTURAZIONE')}
                                          errorField={errors.indirizzo}
                        />
                        <MyFormController register={register('citta')}
                                          label={t('common:USER_DATA:CITTA')}
                                          errorField={errors.citta}
                        />
                        <MyFormController register={register('provincia')}
                                          label={t('common:USER_DATA:PROVINCIA')}
                                          errorField={errors.provincia}
                        />
                        <MyFormController register={register('cap')}
                                          label={t('common:USER_DATA:CAP')}
                                          errorField={errors.cap}
                        />

                        <FormControl isInvalid={!!errors.nazione}>
                            <FormLabel color={'titleColor'}>{t('common:USER_DATA:NAZIONE')}</FormLabel>
                            <Controller
                                control={control}
                                name="nazione"
                                render={({field}) => (
                                    <SelectAutocomplete placeholder={t('common:USER_DATA:NAZIONE')}
                                                        itemList={states ? states : []}
                                                        value={field.value}
                                                        itemRef={field.ref}
                                                        onChange={(codiceStato) => {
                                                            const stato = states.find(s => s.codice === codiceStato);
                                                            if (stato) {
                                                                setValue('nazione', codiceStato)
                                                                field.onChange(stato.descrizione);
                                                                setIsItalian(stato.descrizione === 'ITALIA'); // Update isItalian state
                                                            }
                                                        }}
                                                        renderItem={(state) => (
                                                            <Option key={state.id}
                                                                    value={state.codice}>{state.descrizione}</Option>
                                                        )}
                                    />
                                )}/>
                            <FormErrorMessage>{t(`${errors.nazione}`)}</FormErrorMessage>
                        </FormControl>
                        {/*End Form */}
                        <Box h={30}/>
                        <MyButton myType={'PRIMARY'} isLoading={isSubmitting}
                                  type="submit">{t('common:BUTTONS:CONTINUE')}</MyButton>
                        <Box mt={3} __css={safeArea}>
                            <MenuLanguageComponent/>
                        </Box>
                    </VStack>
                </form>
            </Box>
        </MyPage>
    );
};
