import {createAction, createAsyncThunk} from '@reduxjs/toolkit';
import {CheckInService} from 'service/checkIn.service';
import {RootState} from 'store/store.config';
import {UtenteBaseModel, UtenteConDocumentoModel} from 'models/user.model';
import {CheckInStatusResponse, SaveCheckinParams} from 'store/checkin/types';
import {uiManagerActions} from 'store/uiManager/uiManager.action';
import {DocumentModel} from 'models/document.model';
import {DatiFatturazioneModel} from 'models/prenotazione.model';
import {GeolocationWithPermissionModel} from 'models/geolocation.model';
import {PostPrenotazioneService} from 'service/postPrenotazione.service';
import {UtenteConDocumentoFields, VeriffExtra} from "service/mappers/veriff/types";

export const enum CHECKIN_ACTION {
    FETCH_BOOKING = 'FETCH_BOOKING',
    SAVE_UTENTE_CAPOGRUPPO = 'SAVE_UTENTE_CAPOGRUPPO',
    DELETE_UTENTE_CAPOGRUPPO = 'DELETE_UTENTE_CAPOGRUPPO',
    CONFIRM_BOOKING = 'CONFIRM_BOOKING',
    ANALYSE_IMAGE = 'ANALYSE_IMAGE',
    ADD_GUEST = 'ADD_GUEST',
    EDIT_GUEST = 'EDIT_GUEST',
    ADD_DOCUMENT_TYPE = 'ADD_DOCUMENT_TYPE',
    SAVE_BILLING_DATA = 'SAVE_BILLING_DATA',
    SAVE_GEOLOCATION = 'SAVE_GEOLOCATION',
    SAVE_VERIFF_EXTRA = 'SAVE_VERIFF_EXTRA'
}

const saveUtenteCapoGruppo = createAction(CHECKIN_ACTION.SAVE_UTENTE_CAPOGRUPPO, (payload: {
    utenteCapoGruppo: UtenteConDocumentoModel | undefined,
    stepNumber: number,
    completedFields: UtenteConDocumentoFields[]
}) => {
    return {payload}
})

const saveGeolocation = createAsyncThunk<GeolocationWithPermissionModel, GeolocationWithPermissionModel, any>(CHECKIN_ACTION.SAVE_GEOLOCATION, async (coords, thunkAPI) => {
    try {
        await PostPrenotazioneService.accettazionePosizione(coords) //isFinal ? await CheckInService.fetchBookingRequestMockComplete() :
        return coords
    } catch (e: any) {
        console.log('Error saveGeolocation Action', e)
        throw e
    }
});
const deleteUtenteCapoGruppo = createAction(CHECKIN_ACTION.DELETE_UTENTE_CAPOGRUPPO, () => {
    return {payload: undefined}
})

const fetchBooking = createAsyncThunk(CHECKIN_ACTION.FETCH_BOOKING, async (arg, thunkAPI) => {
    try {
        return await CheckInService.fetchBookingRequest() //isFinal ? await CheckInService.fetchBookingRequestMockComplete() :
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'common:ERRORS:GENERIC:TITLE',
            description: 'common:ERRORS:GENERIC:DESCRIPTION',
            duration: 3000,
            status: 'error',
            isI18nKey: true
        }));
        throw e
    }
});

const addDocumentType = createAction(CHECKIN_ACTION.ADD_DOCUMENT_TYPE, (documentType: DocumentModel) => {
    return {payload: documentType}
});

const confirmCheckIn = createAsyncThunk<CheckInStatusResponse, void>(CHECKIN_ACTION.CONFIRM_BOOKING, async (arg, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState
        const checkIn = state.checkinReducer
        //get the checkin model from the store
        if (checkIn.prenotazione) {
            const finalPrenotazione: SaveCheckinParams = {
                // Create List and convert to backend date
                utenteCheckInList: [
                    ...checkIn.prenotazione.utenteCheckInList,
                    checkIn.prenotazione.utenteCapoGruppo
                ],
                datiFatturazione: checkIn.prenotazione.datiFatturazione
            }
            console.log('finalPrenotazione', finalPrenotazione)
            const checkInStatus = await CheckInService.saveCheckinRequest(finalPrenotazione)
            if (checkInStatus === CheckInStatusResponse.ALREADY_CHECKED_IN) {
                thunkAPI.dispatch(uiManagerActions.showToast({
                    title: 'common:ERRORS:ERRORE_CHECKIN:TITLE',
                    description: 'common:ERRORS:ERRORE_CHECKIN:CHECKIN_ALREADY_DONE',
                    duration: 3000,
                    status: 'error',
                    isI18nKey: true
                }));
            }
            return checkInStatus
        }
        throw new Error('prenotazione not found')
    } catch (e: any) {
        console.log('ERROR CONFIRM CHECKIN', e)
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'common:ERRORS:ERRORE_CHECKIN:TITLE',
            description: 'common:ERRORS:ERRORE_CHECKIN:DESCRIPTION',
            duration: 3000,
            status: 'error',
            isI18nKey: true
        }));
        throw e
    }
});
const addGuest = createAction(CHECKIN_ACTION.ADD_GUEST, (utenteBase: UtenteBaseModel) => {
    return {payload: utenteBase}
})

const editGuest = createAction(CHECKIN_ACTION.EDIT_GUEST, (utenteBase: UtenteBaseModel) => {
    return {payload: utenteBase}
})

const saveBillingData = createAction(CHECKIN_ACTION.SAVE_BILLING_DATA, (billingData: DatiFatturazioneModel) => {
    return {payload: billingData}
})

const saveVeriffExtra = createAction<VeriffExtra>(CHECKIN_ACTION.SAVE_VERIFF_EXTRA);

export const checkinActions = {
    saveUtenteCapoGruppo,
    fetchBooking,
    addGuest,
    deleteUtenteCapoGruppo,
    editGuest,
    confirmCheckIn,
    addDocumentType,
    saveBillingData,
    saveGeolocation,
    saveVeriffExtra
}
