import {createReducer} from '@reduxjs/toolkit';
import {checkinActions} from './checkin.action'
import {CheckinState} from './types';
import {AuthActions} from 'store/auth/auth.action';

const initialState: CheckinState = {
    isLoading: false,
    isError: false,
    completedFields: [],
    stepNumber: 0
};

export const checkinReducer = createReducer(initialState, (builder) => {
    builder.addCase(checkinActions.saveGeolocation.fulfilled, (state, action) => {
        if (state.prenotazione) {
            state.prenotazione = {
                ...state.prenotazione,
                ...action.payload
            }
        }
    });

    builder.addCase(checkinActions.deleteUtenteCapoGruppo, (state, action) => {
        if (state.prenotazione) {
            return {
                ...state,
                stepNumber: 1,
                completedFields: [],
                prenotazione: {
                    ...state.prenotazione,
                    utenteCapoGruppo: undefined
                }
            }
        }
    });
    builder.addCase(checkinActions.saveUtenteCapoGruppo, (state, action) => {
        const stepNumber = action.payload.stepNumber;
        const utenteCapoGruppo = action.payload.utenteCapoGruppo;
        const prenotazione = state.prenotazione;
        const completedFields = action.payload.completedFields;

        if (prenotazione) {
            return {
                ...state,
                isLoading: false,
                isError: false,
                stepNumber: stepNumber ? stepNumber : state.stepNumber,
                completedFields,
                prenotazione: {
                    ...prenotazione,
                    utenteCapoGruppo: utenteCapoGruppo ? utenteCapoGruppo : undefined
                }
            }
        }
        return {...state}
    });
    builder.addCase(checkinActions.addDocumentType, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isError: false,
            utils: {
                documentType: action.payload
            }
        }
    });
    builder.addCase(checkinActions.addGuest, (state, action) => {
        const stepNumber = state.stepNumber + 1;
        if (state.prenotazione) {
            const utenti = state.prenotazione?.utenteCheckInList;
            const utenteBase = action.payload;
            const utenteCheckInList = [...utenti, {...utenteBase}]
            console.log('utenti: ', utenteCheckInList);
            return {
                ...state,
                stepNumber,
                prenotazione: {
                    ...state.prenotazione,
                    utenteCheckInList
                }
            }
        }
        return state
    });
    builder.addCase(checkinActions.editGuest, (state, action) => {
        const prenotazione = state.prenotazione
        if (prenotazione) {
            const utenti = prenotazione.utenteCheckInList;
            const utenteBase = action.payload;
            return {
                ...state,
                prenotazione: {
                    ...prenotazione,
                    utenteCheckInList: [...utenti.map(g => {
                        if (g.uid === utenteBase.uid) {
                            return {
                                ...g,
                                ...utenteBase
                            }
                        }
                        return g
                    })]
                }

            }
        }
        return state;


    });
    builder.addCase(AuthActions.loginAction.fulfilled, (state, action) => {
        const prenotazione = action.payload.prenotazione;
        if (prenotazione) {
            return {
                ...state,
                prenotazione,
                isLoading: false,
                isError: false,
                stepNumber: 0
            }
        }
        return state

    });
    // fetch booking
    builder.addCase(checkinActions.fetchBooking.pending, (state, action) => {
        return {
            ...state,
            isLoading: true,
            isError: false,
        }
    });
    builder.addCase(checkinActions.fetchBooking.fulfilled, (state, action) => {
        const prenotazione = action.payload.prenotazione
        if (prenotazione) {
            return {
                ...state,
                prenotazione,
                isLoading: false,
                isError: false,
            }
        }
        return state

    });
    builder.addCase(checkinActions.fetchBooking.rejected, (state, action) => {
        return {
            ...state,
            isError: true,
            isLoading: false,
            prenotazione: undefined
        }
    });
    // authenticate
    builder.addCase(AuthActions.authenticateWithToken.fulfilled, (state, action) => {
        if (action.payload?.prenotazione) {
            const prenotazione = action.payload.prenotazione
            return {
                ...state,
                prenotazione,
                isLoading: false,
                isError: false
            }
        }
        return state

    });
    builder.addCase(AuthActions.authenticateWithToken.pending, (state, action) => {
        return {
            ...state,
            isError: false,
            isLoading: true,
        }
    });
    builder.addCase(AuthActions.authenticateWithToken.rejected, (state, action) => {
        return {
            ...state,
            isError: true,
            isLoading: false,
        }
    });
    // confirm booking
    builder.addCase(checkinActions.confirmCheckIn.pending, (state, action) => {
        return {
            ...state,
            isLoading: true,
            isError: false,
        }
    });
    builder.addCase(checkinActions.confirmCheckIn.fulfilled, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isError: false,
        }
    });
    builder.addCase(checkinActions.confirmCheckIn.rejected, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isError: true,
        }
    });
    builder.addCase(AuthActions.logoutAction.fulfilled, (state, action) => {
        return {
            isLoading: false,
            isError: false,
            stepNumber: 0,
            prenotazione: undefined,
            completedFields: [],
        }
    });

    builder.addCase(checkinActions.saveBillingData, (state, action) => {
        if (state.prenotazione) {
            if (action.payload.personaFisica) {
                state.prenotazione.datiFatturazione = {
                    ...action.payload,
                    // Remove unused fields
                    partitaIva: undefined,
                    sdi: undefined,
                    ragioneSociale: undefined,
                };
            } else {
                state.prenotazione.datiFatturazione = {
                    ...action.payload,
                    // Remove unused fields
                    nome: undefined,
                    cognome: undefined,
                    codiceFiscale: undefined,
                };
            }
        }
    });

    builder.addCase(checkinActions.saveVeriffExtra, (state, action) => {
        state.veriffExtra = action.payload
    });

    builder.addDefaultCase((state, action) => {
        return state;
    });

});
