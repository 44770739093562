import React, {Suspense} from 'react';
import './App.css';
import {Route, Switch} from 'react-router-dom';
import {AppRoutes} from 'navigation/routes';
import {i18Mfe} from 'i18n/i18n.config';
import {I18nextProvider} from 'react-i18next';
import CheckInPrivateRoute from 'navigation/checkInPrivateRoute';
import HomePrivateRoutes from 'navigation/homePrivateRoutes';
import BaseRoute from 'navigation/baseRoute';
import PrivateRoute from 'navigation/privateRoute';
import {ToastProvider} from 'providers/toast.provider';
import {Center, Spinner} from '@chakra-ui/react';
import {isDesktop} from 'react-device-detect';
import {DeviceNotSupportedPage} from 'shared/components/deviceNotSupportedPage/deviceNotSupported.page';
import {AuthProvider} from 'providers/auth.provider';
import {AlertProvider} from 'shared/components/allerts/alertProvider/alert.provider';
import {getEnvToBoolean} from "utils/utils";

function App() {

    const handleRender = () => {
        const isVeriffEnabled: boolean = getEnvToBoolean(process.env.REACT_APP_IS_VERIFF_ENABLED);

        if (isDesktop) {
            return (
                <DeviceNotSupportedPage/>
            )
        }

        return (
            <ToastProvider>
                <AlertProvider>
                    <Switch>
                        <BaseRoute {...AppRoutes.LOGIN}/>
                        <HomePrivateRoutes {...AppRoutes.TAB}/>
                        <CheckInPrivateRoute {...AppRoutes.SUMMINGUP}/>
                        <CheckInPrivateRoute {...AppRoutes.DOCUMENT_SELECTION}/>
                                <CheckInPrivateRoute {...AppRoutes.DOCUMENT_SCANNING}/>
                                <Route {...AppRoutes.DOCUMENT_SCANNING_ANALYSIS}/>
                        <CheckInPrivateRoute {...AppRoutes.SUMMINGUP_ADDBOOKER}/>
                        <CheckInPrivateRoute {...AppRoutes.SUMMINGUP_BILLING}/>
                        <CheckInPrivateRoute {...AppRoutes.SUMMINGUP_ADDUSER}/>
                        <CheckInPrivateRoute {...AppRoutes.FINAL_SUMMINGUP}/>
                        <PrivateRoute {...AppRoutes.SUCCESS}/>
                        {/*<Route {...AppRoutes.TEST}/>*/}
                        <Route {...AppRoutes.NOT_FOUND}/>
                    </Switch>
                </AlertProvider>
            </ToastProvider>
        )
    }

    return (
        <Suspense fallback={
            <Center h={'100vh'} w={'full'}>
                <Spinner/>
            </Center>
        }>
            <I18nextProvider i18n={i18Mfe} defaultNS={'common'}>
                <AuthProvider>
                    {handleRender()}
                </AuthProvider>
            </I18nextProvider>
        </Suspense>

    );
}

export default App;
