import React, {FC} from 'react';
import {StackProps, Text, VStack} from '@chakra-ui/react';

interface MySectionProps extends StackProps{
    title:string,
    backgroundColor?:string
}

const MySection:FC<MySectionProps> = (props) => {
    const {title,backgroundColor,...baseProps} = props

    return (
    <VStack w={'full'} align={'start'} backgroundColor={backgroundColor ?? undefined}>
       <Text my={2} fontWeight={'medium'} color={'titleColor'} fontSize={'lg'} >{props.title}</Text>
        <VStack w={'full'} {...baseProps} >
            {props.children}
        </VStack>
    </VStack>
    )
};

export default MySection
