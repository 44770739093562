import React, {FC} from 'react';
import {Box, Flex, Text} from '@chakra-ui/react';
import LogoImage from 'assets/logos/homehotel_b.png';
import {useHistory} from 'react-router-dom';
import {RoutesPaths} from 'navigation/routes';
import {HEADER_HEIGHT} from 'shared/components/defaultComponents/myPage/myPage.component';

export interface HeaderProps {
    pageName: string
}

export const HeaderComponent:FC<HeaderProps> = ({pageName}) => {
    const history = useHistory();

    return (
        <Box position={'sticky'} h={HEADER_HEIGHT}  w={'full'} px={2}  boxShadow='base' top={0} left={0} zIndex={1000} m={0} backgroundColor={'background'} >
                <Flex h={'full'} w={'full'} justifyContent={'space-between'} alignItems={'center'} m={0}>
                    <img onClick={()=>history.push(RoutesPaths.TAB.toString())} src={LogoImage} width={120}/>
                    <Text fontSize={'md'} fontWeight={'medium'} color={'titleColor'} >{pageName}</Text>
                </Flex>
        </Box>
    );
};
