import React, {useState} from 'react';
import {Box, Spacer, Text} from '@chakra-ui/react';
import MyButton from 'shared/components/defaultComponents/myButton/myButton.component';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {checkinActions} from 'store/checkin/checkin.action';
import enTexts from './i18n/en.json';
import itTexts from './i18n/it.json';
import {loadTranslations} from 'i18n/i18n.service';
import {MenuLanguageComponent} from 'i18n/component/menuLanguage.component';
import {RoutesPaths} from 'navigation/routes';
import {useHistory} from 'react-router-dom';
import MyPage from 'shared/components/defaultComponents/myPage/myPage.component';
import {default as SuccessImg} from "../../assets/general/success.svg";
import {MyImage} from "../../shared/components/defaultComponents/myImage/myImage.component";

export const SuccessPage = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const i18nTexts = {
        'en': enTexts,
        'it': itTexts,
    };
    loadTranslations('successPage', i18nTexts);
    const dispatch = useAppDispatch()
    const {isError} = useAppSelector(state => state.checkinReducer);
    const [isLoading, setIsLoading] = useState(false);

    const handleNavigation = async () => {
        setIsLoading(true);
        await dispatch(checkinActions.fetchBooking())
        setIsLoading(false);
        history.push(RoutesPaths.TAB.toString())
    };


    return (
        <MyPage header={{pageName: t('successPage:TEXTS.TITLE')}} isError={isError}
                mainContainerProps={{align: 'start'}}>
            <Box h={35}/>
            <Box h={"full"} width={'full'} px={3}>
                <Text fontSize={'x-large'} color={'titleColor'}
                      fontWeight={'bold'}>{t('successPage:TEXTS.INTRO_1')}</Text>
                <Text fontSize={'md'} color={'subtitleColor'}>{t('successPage:TEXTS.INTRO_2')}</Text>
                <Spacer/>
                <MyImage src={SuccessImg} style={{width: 300, height: 300}}/>
                <Spacer/>
                <Box w={'full'}>
                    <MyButton myType={'PRIMARY'} isLoading={isLoading}
                              onClick={handleNavigation}><Text>{t('successPage:BUTTONS:NEXT')}</Text> </MyButton>
                </Box>
                <Box my={3} w={'full'} justifyContent={'center'}>
                    <MenuLanguageComponent/>
                </Box>
            </Box>

        </MyPage>
    );
};
