import React, {FC, useEffect, useState} from 'react';
import {Box, FormControl, FormErrorMessage, FormLabel, Select, Text, VStack} from '@chakra-ui/react';
import MyButton from 'shared/components/defaultComponents/myButton/myButton.component';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {checkinActions} from 'store/checkin/checkin.action';
import {useHistory, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {MenuLanguageComponent} from 'i18n/component/menuLanguage.component';
import enTexts from 'screens/summingUp/i18n/en.json';
import itTexts from 'screens/summingUp/i18n/it.json';
import {loadTranslations} from 'i18n/i18n.service';
import {
    UtenteConDocumentoFormData,
    utenteConDocumentoFormValidationOrder
} from 'screens/summingUp/screens/summingUpAddBooker/types';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {UtenteConDocumentoValidationSchema} from 'screens/summingUp/screens/summingUpAddBooker/addBooker.validation';
import {Genders, UtenteConDocumentoModel} from 'models/user.model';
import 'antd/dist/antd.css';
import {borderRadius, safeArea} from 'style/styleConstants';
import {autocompleteActions} from 'store/autocomplete/autocomplete.action';
import {Option, SelectAutocomplete} from 'shared/components/selectAutocomplete/selectAutocomplete.component';
import {EditBookerParams, RoutesPaths} from 'navigation/routes';
import {setPropertiesForUtenteConDocumento} from 'utils/checkin.utils';
import {ReactComponent as NoData} from 'assets/general/no_data.svg';
import MyPage from 'shared/components/defaultComponents/myPage/myPage.component';
import {MyDatePicker} from 'shared/components/defaultComponents/myDatePicker/myDatePicker.component';
import {UtenteConDocumentoFields} from 'service/mappers/veriff/types';
import {MyFormController} from "shared/components/defaultComponents/myFormController/myFormController.component";
import {CheckInService} from "service/checkIn.service";

const SummingUpAddBooker: FC = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const {t} = useTranslation();
    const i18nTexts = {
        'en': enTexts,
        'it': itTexts,
    };
    loadTranslations('summingUpPage', i18nTexts)

    const {
        isError,
        isLoading,
        stepNumber,
        prenotazione,
        completedFields,
        utils
    } = useAppSelector(state => state.checkinReducer);

    const autocompleteState = useAppSelector(state => state.autocompleteReducer);
    const {states, cities, documents} = autocompleteState
    const {edit} = useParams<EditBookerParams>();

    //Form
    const [formDefaultValues, setFormDefaultValues] = useState<UtenteConDocumentoModel>(UtenteConDocumentoFormData());
    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        setFocus,
        formState: {errors, isSubmitting}
    } = useForm<UtenteConDocumentoModel>({
        resolver: yupResolver(UtenteConDocumentoValidationSchema),
        defaultValues: formDefaultValues,
    });
    const [isBornInItaly, setIsBornInItaly] = useState(false);
    const [isLivingInItaly, setIsLivingInItaly] = useState(false);
    const [isDocumentReleasedInItaly, setIsDocumentReleasedInItaly] = useState(false);

    useEffect(() => {
        reset(UtenteConDocumentoFormData({
            tipoDocumento: utils?.documentType?.descrizione,
            tipoDocumentoPortale: utils?.documentType?.codice
        }));

        // Set document type
        const localDocumentType = CheckInService.getDocumentType()
        console.log('localDocumentType', localDocumentType)
        if (localDocumentType) {
            setValue('tipoDocumento', localDocumentType.tipoDocumento);
            setValue('tipoDocumentoPortale', localDocumentType.tipoDocumentoPortale);
        }

        if (prenotazione?.utenteCapoGruppo) {
            setFormDefaultValues(prenotazione.utenteCapoGruppo);
            reset(prenotazione.utenteCapoGruppo);
            console.log('SET capogruppo form : ', prenotazione.utenteCapoGruppo)

            if (prenotazione.utenteCapoGruppo.statoNascita === 'ITALIA') {
                setIsBornInItaly(true);
            }
            if (prenotazione.utenteCapoGruppo.nazioneDiResidenza === 'ITALIA') {
                setIsLivingInItaly(true);
            }
            if (prenotazione.utenteCapoGruppo.luogoRilascioDocumento === 'ITALIA') {
                setIsDocumentReleasedInItaly(true);
            }
        }
        //Autocomplete states
        if (states.length === 0) {
            dispatch(autocompleteActions.fetchStates())
        }
        //Autocomplete documents
        if (documents.length === 0) {
            dispatch(autocompleteActions.fetchDocuments())
        }
    }, []);

    const formSubmit = async (utente: UtenteConDocumentoModel) => {
        const utenteCapoGruppo = setPropertiesForUtenteConDocumento(utente, prenotazione?.numeroUtentiOspite, '', '', utils?.documentType);
        console.log('utenteCapoGruppo', utenteCapoGruppo)
        await dispatch(checkinActions.saveUtenteCapoGruppo({
            utenteCapoGruppo,
            stepNumber: stepNumber === 0 ? 1 : stepNumber,
            completedFields
        })) // go from 0 to 1 because we added the booker, if the step number is higher it means that we are editing the user
        if (edit) {
            return history.push(RoutesPaths.FINAL_SUMMINGUP.toString())
        }
        return history.push(RoutesPaths.SUMMINGUP_BILLING.toString())
    }

    // Focus on error
    useEffect(() => {
        const firstError = utenteConDocumentoFormValidationOrder.reduce((field, a) => {
            if (field) {
                return !!errors[field] ? field : a;
            }
            return a;
        }, undefined)

        try {
            if (firstError && errors[firstError] && errors[firstError]?.ref !== undefined) {
                setFocus(firstError as keyof typeof errors)
            }
        } catch (e) {
            console.log('error', e)
        }

    }, [errors, setFocus]);

    const isPreCompleted = (field: UtenteConDocumentoFields): boolean => {
        return completedFields?.includes(field)
    }

    return (
        <MyPage header={{pageName: t('summingUpPage:TEXTS:ADD_BOOKER')}}
                isError={isError}
                isLoading={isLoading}>
            <Box height={'100%'} width={'100%'} px={3}>
                <form onSubmit={handleSubmit(formSubmit)}>
                    <VStack w={'full'} mt={2}>
                        <MyFormController register={register('nome')}
                                          label={t('common:USER_DATA:NOME')}
                                          isDisabled={isPreCompleted('nome')}
                                          errorField={errors.nome}
                        />
                        <MyFormController register={register('cognome')}
                                          label={t('common:USER_DATA:COGNOME')}
                                          isDisabled={isPreCompleted('cognome')}
                                          errorField={errors.cognome}
                        />
                        <FormControl isInvalid={!!errors.dataDiNascita}>
                            <FormLabel color={'titleColor'}>{t('common:USER_DATA:DATA_DI_NASCITA')}</FormLabel>
                            <MyDatePicker control={control}
                                          name="dataDiNascita"
                                          isDisabled={isPreCompleted('dataDiNascita')}
                                          placeholder={t('common:USER_DATA:DATA_DI_NASCITA')}
                            />
                            <FormErrorMessage>{t(`${errors.dataDiNascita?.message}`)}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={!!errors.statoNascita}>
                            <FormLabel htmlFor={'statoNascita'} color={'titleColor'}>{t('common:USER_DATA:STATO_NASCITA')}</FormLabel>
                            <Controller
                                render={({field}) => (
                                    <SelectAutocomplete placeholder={t('common:USER_DATA:STATO_NASCITA')}
                                                        itemList={states ? states : []}
                                                        id={'statoNascita'}
                                                        value={field.value}
                                                        isDisabled={isPreCompleted('statoNascita')}
                                                        itemRef={field.ref}
                                                        onChange={(codiceStato) => {
                                                            const stato = states.find(s => s.codice === codiceStato);
                                                            if (stato) {
                                                                setValue('statoNascitaPortale', codiceStato)
                                                                field.onChange(stato.descrizione);
                                                                if (stato.descrizione === 'ITALIA') {
                                                                    console.log('statoNascita ', getValues('nazionalita'))
                                                                    setIsBornInItaly(true)
                                                                } else {
                                                                    setIsBornInItaly(false)
                                                                    setValue('luogoDiNascita', '')
                                                                    setValue('luogoDiNascitaPortale', '')
                                                                }
                                                            }
                                                        }}
                                                        renderItem={(state) => (
                                                            <Option key={state.id}
                                                                    value={state.codice}>{state.descrizione}</Option>
                                                        )}
                                    />
                                )}
                                name="statoNascita"
                                control={control}
                                rules={{required: true}}/>
                            <FormErrorMessage>{t(`${errors.statoNascita?.message}`)}</FormErrorMessage>
                        </FormControl>

                        {isBornInItaly && ( // SOLO ITALIA
                            <FormControl isInvalid={!!errors.luogoDiNascita}>
                                <FormLabel htmlFor={'luogoDiNascita'} color={'titleColor'}>{t('common:USER_DATA:LUOGO_DI_NASCITA')}</FormLabel>
                                <Controller
                                    control={control}
                                    name="luogoDiNascita"
                                    render={({field}) => (
                                        <SelectAutocomplete placeholder={t('common:USER_DATA:LUOGO_DI_NASCITA')}
                                                            itemList={cities ? cities : []}
                                                            id={'luogoDiNascita'}
                                                            value={field.value}
                                                            isDisabled={isPreCompleted('luogoDiNascita')}
                                                            itemRef={field.ref}
                                                            onSearch={(value) => {
                                                                dispatch(autocompleteActions.fetchCitiesSearch(value));
                                                            }}
                                                            noFoundContent={
                                                                <VStack>
                                                                    <NoData height={50}/>
                                                                    <Text>{t('summingUpPage:TEXTS:WRITE_YOUR_CITY')}</Text>
                                                                </VStack>
                                                            }
                                                            onChange={(codice) => {
                                                                const city = cities.find(s => s.codice === codice);
                                                                if (city) {
                                                                    setValue('luogoDiNascitaPortale', codice)
                                                                    field.onChange(city.descrizione);
                                                                }
                                                            }}
                                                            renderItem={(state) => (
                                                                <Option key={state.id}
                                                                        value={state.codice}>{state.descrizione}</Option>
                                                            )}
                                        />
                                    )}/>
                                <FormErrorMessage>{t(`${errors.luogoDiNascita?.message}`)} </FormErrorMessage>
                            </FormControl>
                        )}

                        <FormControl isInvalid={!!errors.nazionalita}>
                            <FormLabel htmlFor={'nazionalita'} color={'titleColor'}>{t('common:USER_DATA:NAZIONALITA')}</FormLabel>
                            <Controller
                                control={control}
                                name="nazionalita"
                                render={({field}) => (
                                    <SelectAutocomplete placeholder={t('common:USER_DATA:NAZIONALITA')}
                                                        itemList={states ? states : []}
                                                        id={'nazionalita'}
                                                        value={field.value}
                                                        itemRef={field.ref}
                                                        isDisabled={isPreCompleted('nazionalita')}
                                                        onChange={(codiceStato) => {
                                                            const stato = states.find(s => s.codice === codiceStato);
                                                            if (stato) {
                                                                setValue('nazionalitaPortale', codiceStato)
                                                                field.onChange(stato.descrizione);
                                                            }
                                                        }}
                                                        renderItem={(state) => (
                                                            <Option key={state.id}
                                                                    value={state.codice}>{state.descrizione}</Option>
                                                        )}
                                    />
                                )}/>
                            <FormErrorMessage>{t(`${errors.nazionalita?.message}`)}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={!!errors.sesso}>
                            <FormLabel color={'titleColor'}>{t('common:USER_DATA:SESSO:NOME')}</FormLabel>
                            <Select {...register('sesso')} borderColor={'borderColor'} borderRadius={borderRadius}>
                                <option defaultChecked={true}
                                        value={Genders.MALE}> {t('common:USER_DATA:SESSO:MASCHIO')} </option>
                                <option value={Genders.FEMALE}> {t('common:USER_DATA:SESSO:FEMMINA')} </option>
                            </Select>
                            <FormErrorMessage>{t(`${errors.sesso?.message}`)}</FormErrorMessage>
                        </FormControl>

                        <FormControl  isInvalid={!!errors.nazioneDiResidenza}>
                            <FormLabel htmlFor={'nazioneDiResidenza'} color={'titleColor'}>{t('common:USER_DATA:NAZIONE_DI_RESIDENZA')}</FormLabel>
                            <Controller
                                control={control}
                                name="nazioneDiResidenza"
                                render={({field}) => (
                                    <SelectAutocomplete placeholder={t('common:USER_DATA:NAZIONE_DI_RESIDENZA')}
                                                        itemList={states ? states : []}
                                                        id={'nazioneDiResidenza'}
                                                        value={field.value}
                                                        itemRef={field.ref}
                                                        isDisabled={isPreCompleted('nazioneDiResidenza')}
                                                        onChange={(codiceStato) => {
                                                            const stato = states.find(s => s.codice === codiceStato);
                                                            if (stato) {
                                                                setValue('nazioneDiResidenzaPortale', codiceStato)
                                                                field.onChange(stato.descrizione);
                                                                if (stato.descrizione === 'ITALIA') {
                                                                    console.log('nazioneDiResidenza ', getValues('nazionalita'))
                                                                    setIsLivingInItaly(true)
                                                                } else {
                                                                    setIsLivingInItaly(false)
                                                                    setValue('comuneDiResidenza', '')
                                                                    setValue('comuneDiResidenzaPortale', '')
                                                                }
                                                            }
                                                        }}
                                                        renderItem={(state) => (
                                                            <Option key={state.id}
                                                                    value={state.codice}>{state.descrizione}</Option>
                                                        )}
                                    />
                                )}/>
                            <FormErrorMessage>{t(`${errors.nazioneDiResidenza?.message}`)}</FormErrorMessage>
                        </FormControl>

                        {isLivingInItaly && ( // SOLO ITALIA
                            <FormControl  isInvalid={!!errors.comuneDiResidenza}>
                                <FormLabel htmlFor={'comuneDiResidenza'} color={'titleColor'}>{t('common:USER_DATA:COMUNE_DI_RESIDENZA')}</FormLabel>
                                <Controller
                                    control={control}
                                    name="comuneDiResidenza"
                                    render={({field}) => (
                                        <SelectAutocomplete placeholder={t('common:USER_DATA:COMUNE_DI_RESIDENZA')}
                                                            itemList={cities ? cities : []}
                                                            id={'comuneDiResidenza'}
                                                            value={field.value}
                                                            isDisabled={isPreCompleted('comuneDiResidenza')}
                                                            itemRef={field.ref}
                                                            onSearch={(value) => {
                                                                dispatch(autocompleteActions.fetchCitiesSearch(value));
                                                            }}
                                                            noFoundContent={
                                                                <VStack>
                                                                    <NoData height={50}/>
                                                                    <Text>{t('summingUpPage:TEXTS:WRITE_YOUR_CITY')}</Text>
                                                                </VStack>
                                                            }
                                                            onChange={(codice) => {
                                                                const city = cities.find(s => s.codice === codice);
                                                                if (city) {
                                                                    setValue('comuneDiResidenzaPortale', codice)
                                                                    field.onChange(city.descrizione);
                                                                }
                                                            }}
                                                            renderItem={(state) => (
                                                                <Option key={state.id}
                                                                        value={state.codice}>{state.descrizione}</Option>
                                                            )}
                                        />
                                    )}/>
                                <FormErrorMessage>{t(`${errors.comuneDiResidenza?.message}`)}</FormErrorMessage>
                            </FormControl>
                        )}

                        {/*Documento*/}
                        <MyFormController register={register('numeroDocumento')}
                                          label={t('common:USER_DATA:NUMERO_DOCUMENTO')}
                                          isDisabled={isPreCompleted('numeroDocumento')}
                                          errorField={errors.numeroDocumento}
                        />

                        <FormControl isInvalid={!!errors.tipoDocumento}>
                            <FormLabel htmlFor={'tipoDocumento'} color={'titleColor'}>{t('common:USER_DATA:TIPO_DOCUMENTO')}</FormLabel>
                            <Controller
                                control={control}
                                name="tipoDocumento"
                                render={({field}) => (
                                    <SelectAutocomplete placeholder={t('common:USER_DATA:TIPO_DOCUMENTO')}
                                                        itemList={documents}
                                                        id={'tipoDocumento'}
                                                        isDisabled={isPreCompleted('tipoDocumento')}
                                                        value={field.value}
                                                        itemRef={field.ref}
                                                        onChange={(codiceDocumento) => {
                                                            const documento = documents.find(s => s.codice === codiceDocumento);
                                                            if (documento) {
                                                                setValue('tipoDocumentoPortale', codiceDocumento)
                                                                setValue('tipoDocumento', documento.descrizione);
                                                                field.onChange(documento.descrizione);
                                                            }
                                                        }}
                                                        renderItem={(state) => (
                                                            <Option key={state.id}
                                                                    value={state.codice}>{state.descrizione}</Option>
                                                        )}
                                    />
                                )}/>
                            <FormErrorMessage>{t(`${errors.tipoDocumento?.message}`)}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={!!errors.scadenzaDocumento}>
                            <FormLabel color={'titleColor'}>{t('common:USER_DATA:SCADENZA_DOCUMENTO')}</FormLabel>
                            <MyDatePicker control={control}
                                          name="scadenzaDocumento"
                                          isDisabled={isPreCompleted('scadenzaDocumento')}
                                          placeholder={t('common:USER_DATA:SCADENZA_DOCUMENTO')}
                                          allowClear={false}
                            />
                            <FormErrorMessage>{t(`${errors.scadenzaDocumento?.message}`)}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={!!errors.dataRilascioDocumento}>
                            <FormLabel color={'titleColor'}>{t('common:USER_DATA:DATA_RILASCIO_DOCUMENTO')}</FormLabel>
                            <MyDatePicker control={control}
                                          name="dataRilascioDocumento"
                                          isDisabled={isPreCompleted('dataRilascioDocumento')}
                                          placeholder={t('common:USER_DATA:DATA_RILASCIO_DOCUMENTO')}
                                          allowClear={false}
                            />
                            <FormErrorMessage>{t(`${errors.dataRilascioDocumento?.message}`)}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={!!errors.luogoRilascioDocumento}>
                            <FormLabel htmlFor={'luogoRilascioDocumento'} color={'titleColor'}>{t('common:USER_DATA:LUOGO_RILASCIO_DOCUMENTO')}</FormLabel>
                            <Controller
                                control={control}
                                name="luogoRilascioDocumento"
                                render={({field}) => (
                                    <SelectAutocomplete placeholder={t('common:USER_DATA:LUOGO_RILASCIO_DOCUMENTO')}
                                                        itemList={states ? states : []}
                                                        id={'luogoRilascioDocumento'}
                                                        value={field.value}
                                                        itemRef={field.ref}
                                                        isDisabled={isPreCompleted('luogoRilascioDocumento')}
                                                        onChange={(codiceStato) => {
                                                            const stato = states.find(s => s.codice === codiceStato);
                                                            if (stato) {
                                                                setValue('luogoRilascioDocumentoPortale', codiceStato)
                                                                field.onChange(stato.descrizione);
                                                                if (stato.descrizione === 'ITALIA') {
                                                                    console.log('luogoRilascioDocumento ', getValues('luogoRilascioDocumento'))
                                                                    setIsDocumentReleasedInItaly(true)
                                                                } else {
                                                                    setIsDocumentReleasedInItaly(false)
                                                                    setValue('comuneRilascioDocumento', '')
                                                                    setValue('comuneRilascioDocumentoPortale', '')
                                                                }
                                                            }
                                                        }}
                                                        renderItem={(state) => (
                                                            <Option key={state.id}
                                                                    value={state.codice}>{state.descrizione}</Option>
                                                        )}
                                    />
                                )}/>
                            <FormErrorMessage>{t(`${errors.luogoRilascioDocumento?.message}`)}</FormErrorMessage>
                        </FormControl>

                        {isDocumentReleasedInItaly &&
                            <FormControl isInvalid={!!errors.comuneRilascioDocumento}>
                                <FormLabel htmlFor={'comuneDiRilascioDocumento'}
                                    color={'titleColor'}>{t('common:USER_DATA:COMUNE_RILASCIO_DOCUMENTO')}</FormLabel>

                                <Controller
                                    control={control}
                                    name="comuneRilascioDocumento"
                                    render={({field}) => (
                                        <SelectAutocomplete
                                            placeholder={t('common:USER_DATA:COMUNE_RILASCIO_DOCUMENTO')}
                                            itemList={cities ? cities : []}
                                            id={'comuneDiRilascioDocumento'}
                                            value={field.value}
                                            isDisabled={isPreCompleted('comuneRilascioDocumento')}
                                            itemRef={field.ref}
                                            onSearch={(value) => {
                                                dispatch(autocompleteActions.fetchCitiesSearch(value));
                                            }}
                                            noFoundContent={
                                                <VStack>
                                                    <NoData height={50}/>
                                                    <Text>{t('summingUpPage:TEXTS:WRITE_YOUR_CITY')}</Text>
                                                </VStack>
                                            }
                                            onChange={(codice) => {
                                                const city = cities.find(s => s.codice === codice);
                                                if (city) {
                                                    setValue('comuneRilascioDocumentoPortale', codice)
                                                    field.onChange(city.descrizione);
                                                }
                                            }}
                                            renderItem={(state) => (
                                                <Option key={state.id}
                                                        value={state.codice}>{state.descrizione}</Option>
                                            )}
                                        />
                                    )}/>
                                <FormErrorMessage>{t(`${errors.comuneRilascioDocumento?.message}`)}</FormErrorMessage>
                            </FormControl>}
                        <Box h={30}/>
                        <MyButton myType={'PRIMARY'} isLoading={isSubmitting}
                                  type={'submit'}>{t(edit ? 'common:BUTTONS:CONFIRM' : 'common:BUTTONS:CONTINUE')}</MyButton>
                        <Box mt={3} pb={10} __css={safeArea}>
                            <MenuLanguageComponent/>
                        </Box>
                    </VStack>
                </form>
            </Box>
        </MyPage>
    )
};

export default SummingUpAddBooker
