import React, {FC} from 'react';
import {Box, BoxProps, Center, Spinner, StackProps, Text, VStack} from '@chakra-ui/react';
import {HeaderComponent, HeaderProps} from 'shared/components/header/header.component';
import MyButton from 'shared/components/defaultComponents/myButton/myButton.component';
import {AuthActions} from 'store/auth/auth.action';
import {useDispatch} from 'react-redux';
import {
    HeaderWithStepper,
    HeaderWithStepperProps
} from 'shared/components/headerWithStepper/headerWithStepper.component';
import {useTranslation} from 'react-i18next';
import {default as OnErrorImg} from 'assets/general/onError.svg'
import {MyImage} from "shared/components/defaultComponents/myImage/myImage.component";

interface MyPageProps {
    header?: HeaderProps,
    headerWithStepper?: HeaderWithStepperProps
    isLoading?: boolean,
    hasTabBar?: boolean,
    isError?: boolean,
    mainContainerProps?: StackProps | BoxProps
}

export const HEADER_HEIGHT = '56px';
export const HEADER_WITH_STEPPER_HEIGHT = '105px';


export const VSpaceBetweenProps = {flexGrow: 1, direction: 'column', align: 'center', justifyContent: 'space-between'}
export const VSpaceEvenlyProps = {flexGrow: 1, direction: 'column', align: 'center', justifyContent: 'space-evenly'}
export const VSpaceStartProps = {flexGrow: 1, direction: 'column', align: 'center', justifyContent: 'start'}
export const HSpaceEvenlyProps = {flexGrow: 1, direction: 'row', align: 'center', justifyContent: 'space-evenly'}
export const HSpaceBetweenProps = {flexGrow: 1, direction: 'row', align: 'center', justifyContent: 'space-between'}

const MyPage: FC<MyPageProps> = ({
                                     header,
                                     hasTabBar,
                                     headerWithStepper,
                                     children,
                                     isError,
                                     isLoading,
                                     mainContainerProps
                                 }) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const renderContent = () => {
        if (isError) {
            return (
                <Center w={'full'} h={'100%'}  px={3}>
                    <VStack w={'full'} h={'100%'} align={'start'} justify={'start'}>
                        <Box h={10} />
                        <Text color={'titleColor'} fontSize={'x-large'}>{t('common:ERRORS:GENERIC:TITLE')}</Text>
                        <VStack w={"full"} align={'center'}>
                            <MyImage src={OnErrorImg} style={{width:300,height:300}} />
                            <Box h={10}/>
                            <MyButton myType={'OUTLINE'} color={'titleColor'} fontWeight={'bold'} onClick={() => dispatch(AuthActions.logoutAction())}>{t('common:BUTTONS:BACK_TO_LOGIN')}</MyButton>
                        </VStack>
                    </VStack>
                </Center>
            )
        }

        if (isLoading) {
            return (
                <Center minH={'100%'} w={'full'} overflowY={'hidden'}>
                    <Spinner/>
                </Center>
            )
        }

        return children
    }

    return (
        <>
                <VStack height={'100%'} backgroundColor={'#FFFFFF'} {...mainContainerProps}>
                    {header && <HeaderComponent pageName={header.pageName}/>}
                    {headerWithStepper && <HeaderWithStepper pageTitle={headerWithStepper.pageTitle}
                                           pageSubTitle={headerWithStepper.pageSubTitle}
                                           stepNumber={headerWithStepper.stepNumber}
                                           totalSteps={headerWithStepper.totalSteps}/>}
                    {renderContent()}
                    <Box h={hasTabBar ? 70 : 0}/>
                </VStack>
        </>


    )
};

export default MyPage
