import {TIPO_ALLOGGIATO, UtenteBaseModel, UtenteConDocumentoModel} from 'models/user.model';
import moment from 'moment';
import {DocumentModel} from 'models/document.model';
import {v4 as uuidv4} from 'uuid';

export const defineTipoAlloggiato = (numeroUtentiOspite: number| undefined): number => {
    if (numeroUtentiOspite && numeroUtentiOspite === 1) {
        return TIPO_ALLOGGIATO.CAPOGRUPPO_SENZA_PARTECIPANTI.valueOf()
    }
    return TIPO_ALLOGGIATO.CAPOGRUPPO_CON_PARTECIPANTI.valueOf()
}

export const generateUniqueUserId = (utente:UtenteConDocumentoModel | UtenteBaseModel): string => {
    const userUUID = uuidv4();
    return `${utente.nome}-${utente.cognome}-${userUUID}`
}

export const getUIFormattedDate = (myDate:string) =>{
    return moment(myDate).format('DD/MM/YYYY')
}


export const setPropertiesForUtenteConDocumento = (utente:UtenteConDocumentoModel,numeroUtentiOspite:number|undefined,frontEncoded:string |undefined,backEncoded:string|undefined,documentType:DocumentModel|undefined):UtenteConDocumentoModel => {
    return {
        ...utente,
        scadenzaDocumento: utente.scadenzaDocumento,
        dataRilascioDocumento: utente.dataRilascioDocumento,
        comuneRilascioDocumento: undefined, //campo non presente nel backend
        comuneRilascioDocumentoPortale:undefined, //campo non presente nel backend
        luogoRilascioDocumento: utente.luogoRilascioDocumento === 'ITALIA' && utente.comuneRilascioDocumento? utente.comuneRilascioDocumento: utente.luogoRilascioDocumento,
        luogoRilascioDocumentoPortale: utente.luogoRilascioDocumento === 'ITALIA' && utente.comuneRilascioDocumentoPortale? utente.comuneRilascioDocumentoPortale: utente.luogoRilascioDocumentoPortale,
        fotoDocumentoFronte: frontEncoded ??  '',
        fotoDocumentoRetro: backEncoded ?? '',
        fotoSelfie: '',
        tipoDocumento: utente.tipoDocumento  ?? 'errore',
        tipoDocumentoPortale: utente.tipoDocumentoPortale  ?? 'errore',
        uid: generateUniqueUserId(utente),
        dataDiNascita: utente.dataDiNascita,
        tipoAlloggiato: defineTipoAlloggiato(numeroUtentiOspite),
    }
}

export const setPropertiesUtenteBase = (utente:UtenteBaseModel,generateUid?:boolean):UtenteBaseModel => {
    const uid = generateUniqueUserId(utente)
    return {
        ...utente,
        dataDiNascita: utente.dataDiNascita,
        tipoAlloggiato: TIPO_ALLOGGIATO.UTENTE_BASE,
        uid: generateUid ? uid : utente.uid
    }
}