import {appAxios} from 'service/axios.config';

const getSupportNumberRequest = async (): Promise<string> => {
    const response:any = await appAxios.get(`/public/contattoAssistenza`)
    console.log('Request [getSupportNumberRequest] ', response.data)
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error getting support number')
}

export const SettingsService = {
    getSupportNumberRequest,
}
