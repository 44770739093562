import {DatiFatturazioneModel, PrenotazioneModel} from 'models/prenotazione.model';
import {UtenteBaseModel, UtenteConDocumentoModel} from 'models/user.model';
import {UtenteConDocumentoFields, VeriffExtra} from "service/mappers/veriff/types";
import {DocumentModel} from "models/document.model";

export enum CheckInStatusResponse {
    "SUCCESS",
    "FAILURE",
    "ALREADY_CHECKED_IN",
}

export interface CheckinState {
    isLoading: boolean;
    isError: boolean;
    stepNumber: number;
    prenotazione?: PrenotazioneModel;
    completedFields: UtenteConDocumentoFields[];
    veriffExtra?: VeriffExtra;
    utils?: UtilsData
}

export interface UtilsData {
    documentType?: DocumentModel;
}

export interface SaveCheckinParams {
    utenteCheckInList: (UtenteBaseModel | UtenteConDocumentoModel | undefined)[]
    datiFatturazione?: DatiFatturazioneModel
}
