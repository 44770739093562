import {LoginAuthenticateResponse, LoginParams, LoginResponseDto} from 'store/auth/types';
import {AxiosResponse} from 'axios';
import {appAxios} from 'service/axios.config';
import {prenotazioneMapper} from "./mappers/prenotazione.mapper";

const loginMethod = async (params: LoginParams):Promise<LoginAuthenticateResponse> => {
    console.log('Request [loginRequest] params:', params);
    const response: AxiosResponse<LoginResponseDto> = await appAxios.post(`/auth/loginCliente`,{},{params:{chiaveAccessoApp:params.chiaveAccessoApp}});
    console.log('Request [loginRequest] ', response);

    if (response && response.status === 200 && response.data && response.data.token) {
        const token = response.data.token;
        const prenotazione = response.data.prenotazione;

        AuthService.setAccessToken(token)
        AuthService.setRefreshToken(response.data.refreshToken)
        AuthService.setChiaveAccessoApp(params.chiaveAccessoApp)

        return {
            isAuth: !!prenotazione,
            prenotazione: prenotazioneMapper(prenotazione),
            hasConfirmedBooking: !!prenotazione.dataCheckInEffettuato
        }
    }
    if (!response?.data) {
        throw new Error('Missing response data')
    }
    if (response.data.token || response.data.refreshToken) {
        throw new Error('Missing tokens in response')
    }

    throw new Error('Generic error during login')
}

const getAccessToken = () => {
    return localStorage.getItem('accessToken');
}

const setAccessToken = (accessToken: string) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
    }
}

const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
}

const setRefreshToken = (token: string) => {
    if (token) {
        localStorage.setItem('refreshToken', token);
    }
}

const getChiaveAccessoApp = () => {
    return localStorage.getItem('chiaveAccessoApp');
}

const setChiaveAccessoApp = (token: string) => {
    if (token) {
        localStorage.setItem('chiaveAccessoApp', token);
    }
}



const resetAccessToken = () => {
    localStorage.clear()
}

export const AuthService = {
    loginMethod,
    getAccessToken,
    setAccessToken,
    resetAccessToken,
    getRefreshToken,
    setRefreshToken,
    getChiaveAccessoApp,
    setChiaveAccessoApp
}
