import React from 'react';
import './checkInTable.css'
import {Grid, GridItem, Text, VStack} from '@chakra-ui/react';
import {timeFormatter} from 'utils/utils';
import {getUIFormattedDate} from 'utils/checkin.utils';
import {IoCalendar, IoTime} from "react-icons/io5";
import {MySquareIcon} from "../MySquareIcon/mySquareIcon.component";
import {useTranslation} from "react-i18next";
import enTexts from "../../../screens/summingUp/i18n/en.json";
import itTexts from "../../../screens/summingUp/i18n/it.json";
import {loadTranslations} from "../../../i18n/i18n.service";
import {StrutturaModel} from "../../../models/struttura.model";
import {TableSummingUpComponent} from "./components/tableSummingUp.component";
import {TableFinalSummingUpComponent} from "./components/tableFinalSummingUp.component";
import {UtenteBaseModel, UtenteConDocumentoModel} from "../../../models/user.model";

interface CheckInTableProps {
    inDate: string,
    inTime: string,
    outDate: string
    outTime: string,

    // Summing up params
    summingUp?:{
        numeroUtentiOspite: number,
        struttura?: StrutturaModel,
        handleDocumentSelection?: ()=>void,
    }
    // Final params
    finalSummingUp?:{
        utenteCapoGruppo?: UtenteConDocumentoModel,
        utenteCheckInList?: UtenteBaseModel[],
        handleContinue: ()=>void,
        viewMode?:boolean,
        isLoading:boolean
    }

}

const CheckInTable = ({inDate, outDate, inTime, outTime,summingUp,finalSummingUp}: CheckInTableProps) => {
    const {t} = useTranslation();
    const i18nTexts = {
        'en': enTexts,
        'it': itTexts,
    };
    loadTranslations('summingUpPage', i18nTexts)

    return (
        <Grid
            w={'full'}
            position={'relative'}
            h={120}
            templateRows='repeat(2, 1fr)'
            templateColumns='repeat(4, 1fr)'
            gap={4}
            backgroundColor={'background'}
            p={0}
        >
            {/* Line 0 Header*/}
            <GridItem rowSpan={1} colSpan={1}>
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
                <VStack justify={'center'} h={"full"}>
                    <Text align={'center'} fontSize={'xs'} color={'titleColor'} fontWeight={'normal'}>CHECK-IN</Text>
                </VStack>
            </GridItem>
            <GridItem colSpan={2}>
                <VStack justify={'center'} h={"full"}>
                    <Text align={'center'} fontSize={'xs'} color={'titleColor'} fontWeight={'normal'}>CHECK-OUT</Text>
                </VStack>
            </GridItem>
            {/* Line 1*/}
            <GridItem rowSpan={1} colSpan={1}>
                <VStack justify={'center'} align={'start'} h={"full"}>
                    <MySquareIcon icon={IoCalendar}/>
                </VStack>
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
                <VStack justify={'center'} h={"full"}>
                    <Text align={'center'} fontWeight={'light'} color={'subtitleColor'}>{`${getUIFormattedDate(inDate)}`}</Text>
                </VStack>
            </GridItem>
            <GridItem colSpan={2}>
                <VStack justify={'center'} h={"full"}>
                    <Text align={'center'} fontWeight={'light'} color={'subtitleColor'}>{`${getUIFormattedDate(outDate)}`}</Text>
                </VStack>
            </GridItem>
            {/* Line 2*/}
            <GridItem rowSpan={1} colSpan={1}>
                <VStack justify={'center'} align={'start'} h={"full"}>
                    <MySquareIcon icon={IoTime}/>
                </VStack>
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
                <VStack justify={'center'} h={"full"}>
                    <Text align={'center'} fontWeight={'light'} color={'subtitleColor'}>{`${timeFormatter(inTime)}`}</Text>
                </VStack>
            </GridItem>
            <GridItem colSpan={2}>
                <VStack justify={'center'} h={"full"}>
                    <Text align={'center'}  fontWeight={'light'} color={'subtitleColor'}>{`${timeFormatter(outTime)}`}</Text>
                </VStack>
            </GridItem>

            {summingUp && !finalSummingUp && summingUp.struttura && <TableSummingUpComponent handleDocumentSelection={summingUp.handleDocumentSelection}
                                                                      numeroUtentiOspite={summingUp.numeroUtentiOspite}
                                                                      struttura={summingUp.struttura}/>}
            {finalSummingUp && summingUp && summingUp.struttura  && (
                <>
                    <TableSummingUpComponent numeroUtentiOspite={summingUp.numeroUtentiOspite} struttura={summingUp.struttura}/>
                    <TableFinalSummingUpComponent
                        handleContinue={finalSummingUp.handleContinue}
                        utenteCapoGruppo={finalSummingUp.utenteCapoGruppo}
                        utenteCheckInList={finalSummingUp.utenteCheckInList}
                        viewMode={finalSummingUp.viewMode}
                        isLoading={finalSummingUp.isLoading}
                        />
                </>
            )}
        </Grid>
    )
};

export default CheckInTable
