import {AxiosResponse} from 'axios';
import {appAxios} from 'service/axios.config';
import {GetVeriffResponse} from "models/veriff.model";

const fetchVeriffResponse = async (sessionId: string): Promise<GetVeriffResponse> => {
    try {
        console.log('Request [fetchVeriffResponse] ')
        const response: AxiosResponse<GetVeriffResponse> = await appAxios.get('/webappguest/getVeriffResponse/' + sessionId)
        console.log('Request [fetchVeriffResponse] DATA:', response.data)
        if (response.status === 200) {
            return {
                veriffResponse: response.data.veriffResponse,
            }
        }
    } catch (e) {
        console.log('Error [fetchVeriffResponse]')
        throw e
    }
    throw new Error('Generic error during fetchVeriffResponse')
}

const setVeriffSessionIdRequest = async (sessionId: string): Promise<boolean> => {
    try {
        console.log('Request [setVeriffSessionIdRequest] ')
        const response: AxiosResponse<void> = await appAxios.post('/webappguest/createVeriffSession', {}, {params: {sessionId}})
        console.log('Request [setVeriffSessionIdRequest] DATA:', response.data)
        if (response.status === 200) {
            return true
        }
    } catch (e) {
        console.log('Error [setVeriffSessionIdRequest]')
    }

    return false
}

const getVeriffSessionId = () => {
    return localStorage.getItem('veriffSessionId');
}

const setVeriffSessionId = (token: string) => {
    if (token) {
        localStorage.setItem('veriffSessionId', token);
    }
}


export const VeriffService = {
    fetchVeriffResponse,
    getVeriffSessionId,
    setVeriffSessionId,
    setVeriffSessionIdRequest
}
