import React from 'react';
import {Redirect, RouteProps} from 'react-router-dom';
import {useAppSelector} from 'store/store.config';
import {RoutesPaths} from 'navigation/routes';
import PrivateRoute from 'navigation/privateRoute';
import {useExitPrompt} from "utils/usePreventExit";

const CheckInPrivateRoute: React.FC<RouteProps> = (props) => {
    const {hasConfirmedBooking} = useAppSelector(state => state.authReducer)
    useExitPrompt(false);


    return !hasConfirmedBooking ?
        (<PrivateRoute path={props.path} exact={props.exact} component={props.component}/>)
        :
        (<Redirect to={RoutesPaths.TAB.toString()}/>);
};
export default CheckInPrivateRoute;
