import {PrenotazioneDTO} from 'models/prenotazione.dto';
import {PrenotazioneModel} from 'models/prenotazione.model';
import {TIPO_ALLOGGIATO, UtenteConDocumentoModel} from 'models/user.model';
import {DateManager} from "../../utils/dateFnsUtils";


export const prenotazioneMapper = (prenotazione:PrenotazioneDTO):PrenotazioneModel => {
    const utenteCapoGruppo = prenotazione.utenteCheckInList.find(utente => {

        if(utente && ( utente.tipoAlloggiato === TIPO_ALLOGGIATO.CAPOGRUPPO_SENZA_PARTECIPANTI || utente.tipoAlloggiato === TIPO_ALLOGGIATO.CAPOGRUPPO_CON_PARTECIPANTI)){
            const utenteConDocumento = utente as UtenteConDocumentoModel
            return {
                ...utente,
                dataDiNascita: DateManager.dateFromBackend(utenteConDocumento?.dataDiNascita) , // Set format to dd/MM/yyyy
                dataRilascioDocumento: utenteConDocumento?.dataRilascioDocumento ? DateManager.dateFromBackend(utenteConDocumento?.dataRilascioDocumento) : undefined, // Set format to dd/MM/yyyy
                scadenzaDocumento: DateManager.dateFromBackend(utenteConDocumento?.scadenzaDocumento), // Set format to dd/MM/yyyy
            };
        }

    }) as UtenteConDocumentoModel;

    const utenteCheckInList = prenotazione.utenteCheckInList.filter(utente => utente?.id !== utenteCapoGruppo?.id);

    return {
        id: prenotazione.id,
        arrivo: prenotazione.arrivo,
        partenza: prenotazione.partenza,
        checkIn: prenotazione.checkIn,
        checkOut: prenotazione.checkOut,
        numeroUtentiOspite: prenotazione.numeroAdulti + prenotazione.numeroBambini,
        numeroAdulti: prenotazione.numeroAdulti,
        numeroBambini: prenotazione.numeroBambini,
        checkInAbilitato: prenotazione.checkInAbilitato,
        utenteCheckInList: utenteCheckInList.map(utente => {
            return {
                ...utente,
                dataDiNascita: DateManager.dateFromBackend(utente.dataDiNascita) // Set format to dd/MM/yyyy
            }
        }),
        utenteCapoGruppo,
        utentePrenotante:{
            nomeOspite: prenotazione.nomeOspite,
            cognomeOspite: prenotazione.cognomeOspite,
            email: prenotazione.email,
            telefono: prenotazione.telefono,
            fotoIdentificazione: prenotazione.fotoIdentificazione,
        },
        datiFatturazione: prenotazione.datiFatturazione ?  {
            nome: prenotazione.datiFatturazione.nome,
            cognome: prenotazione.datiFatturazione.cognome,
            codiceFiscale: prenotazione.datiFatturazione.codiceFiscale,
            ragioneSociale: prenotazione.datiFatturazione.ragioneSociale,
            partitaIva: prenotazione.datiFatturazione.partitaIva,
            sdi: prenotazione.datiFatturazione.sdi,
            indirizzo: prenotazione.datiFatturazione.indirizzo,
            personaFisica: prenotazione.datiFatturazione.personaFisica,
        }: undefined,
        struttura:prenotazione.struttura,
        stato: prenotazione.stato,
        dataCheckInEffettuato: prenotazione?.dataCheckInEffettuato,
        identificazioneInStruttura: prenotazione.identificazioneInStruttura,
        extras: {
            canalePrenotazione: prenotazione.canalePrenotazione,
            idPrenotazioneChannel: prenotazione.idPrenotazioneChannel,
            creato: prenotazione.creato,
            note: prenotazione.note,
            lingua: prenotazione.lingua,
        },
        // Geolocation
        accettazionePosizione: prenotazione.accettazionePosizione,
        latitudine: prenotazione.latitudine,
        longitudine: prenotazione.longitudine,
        // Veriff
        veriffSessionId: prenotazione.veriffSessionId,
        veriffStatus: prenotazione.veriffStatus,
    }
}
