import React from 'react';
import {Center, Text, VStack} from '@chakra-ui/react';
import MyButton from 'shared/components/defaultComponents/myButton/myButton.component';
import {useHistory} from 'react-router-dom';
import {RoutesPaths} from 'navigation/routes';
import {useTranslation} from 'react-i18next';
import {default as NotFound} from 'assets/general/not_found.svg';
import MyPage from 'shared/components/defaultComponents/myPage/myPage.component';
import {MyImage} from 'shared/components/defaultComponents/myImage/myImage.component';

const NotFoundPage = () => {
    const history = useHistory();
    const {t} = useTranslation();

    return (
        <MyPage header={{pageName: ''}} mainContainerProps={{align:'center'}}>
            <Center>
                <VStack>
                    <MyImage src={NotFound} style={{width:300,height:300}} />
                    <Text color={'titleColor'} fontSize={'large'}>{t('common:ERRORS:404:DESCRIPTION')}</Text>
                    <MyButton myType={'OUTLINE'} color={'titleColor'} fontWeight={'bold'} onClick={()=>history.push(RoutesPaths.LOGIN.toString())} >{t('common:BUTTONS:BACK_TO_LOGIN')} </MyButton>
                </VStack>
            </Center>
        </MyPage>
    )
};

export default NotFoundPage
