import React, {FC, useEffect} from 'react';
import {Box, HStack, Stack, Text, VStack} from '@chakra-ui/react';
import CheckInTable from 'shared/components/checkInTable/checkInTable.component';
import MySection from 'shared/components/defaultComponents/mySection/mySection.component';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {useHistory} from 'react-router-dom';
import {RoutesPaths} from 'navigation/routes';
import {useTranslation} from 'react-i18next';
import enTexts from './i18n/en.json';
import itTexts from './i18n/it.json';
import {loadTranslations} from 'i18n/i18n.service';
import {AuthActions} from 'store/auth/auth.action';
import {uiManagerActions} from 'store/uiManager/uiManager.action';
import MyPage from 'shared/components/defaultComponents/myPage/myPage.component';
import {borderRadius} from '../../style/styleConstants';
import {IoLocationOutline} from 'react-icons/io5';
import {appThemeColors} from '../../style/chakraTheme';
import {MyImage} from '../../shared/components/defaultComponents/myImage/myImage.component';
import {VeriffService} from "../../service/veriff.service";

interface SummingUpProps {
}


const SummingUp: FC<SummingUpProps> = (props) => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const {t} = useTranslation();
    const i18nTexts = {
        'en': enTexts,
        'it': itTexts,
    };
    loadTranslations('summingUpPage', i18nTexts)

    const checkIn = useAppSelector(state => state.checkinReducer);
    const {prenotazione, isLoading, isError} = checkIn;

    useEffect(() => {
        if (prenotazione?.numeroUtentiOspite === 0 || prenotazione?.numeroUtentiOspite === undefined || prenotazione?.numeroUtentiOspite === null) {
            dispatch(AuthActions.logoutAction())
            dispatch(uiManagerActions.showToast({
                title: 'common:ERRORS:INVALID_DATA:TITLE',
                description: 'common:ERRORS:INVALID_DATA:DESCRIPTION',
                isI18nKey: true,
                duration: 6000,
                isClosable: true,
                status: 'error',
            }))
        }
    }, []);

    const handleDocumentSelection = () => {
        if(prenotazione?.veriffSessionId && prenotazione?.veriffStatus == 'success'){
            VeriffService.setVeriffSessionId(prenotazione?.veriffSessionId);
            return history.push(RoutesPaths.DOCUMENT_SCANNING_ANALYSIS.toString())
        }
        history.push(RoutesPaths.DOCUMENT_SELECTION.toString())
    }

    return (
        <MyPage header={{pageName: t('summingUpPage:TEXTS:TITLE')}}
                isLoading={isLoading || !prenotazione}
                isError={isError}
                mainContainerProps={{justifyItems: 'space-between', w: 'full'}}>
            <Stack position={'absolute'} w={"full"}>
                <Box w={'full'} h={'250'} position={'fixed'} top={50}>
                    <MyImage src={prenotazione?.struttura.linkImmagine}/>
                </Box>
                <VStack position={'relative'}
                        top={250}
                        zIndex={20}
                        paddingX={3}
                        backgroundColor={'background'}
                        height={'calc(100vh - 200px)'}
                        borderTopRightRadius={borderRadius * 8}>
                    <Box w={'full'} justifyContent={'start'} align={'start'} backgroundColor={'background'}>
                        <Text fontSize={'x-large'} fontWeight={'bold'} color={'titleColor'}
                              pt={2}>{prenotazione?.struttura.nomeStruttura}</Text>
                        <HStack>
                            <IoLocationOutline color={appThemeColors.iconColor["500"]}/>
                            <Text mb={2} fontSize={'md'} color={'subtitleColor'}>{`${prenotazione?.struttura?.strutturaIndirizzo?.citta}, ${prenotazione?.struttura?.strutturaIndirizzo?.indirizzo}`}</Text>
                        </HStack>
                    </Box>
                    <MySection title={t('summingUpPage:TEXTS:DETAIL')} backgroundColor={'background'}>
                        {prenotazione &&
                            <CheckInTable inDate={prenotazione.arrivo} outDate={prenotazione.partenza}
                                          inTime={prenotazione.checkIn}
                                          outTime={prenotazione.checkOut}
                                          summingUp={{
                                              numeroUtentiOspite: prenotazione.numeroUtentiOspite,
                                              struttura: prenotazione.struttura,
                                              handleDocumentSelection
                                          }}
                            />}
                    </MySection>
                </VStack>
            </Stack>
        </MyPage>
    )
};

export default SummingUp
