import {veriffDocumentType, VeriffResponse} from "models/veriff.model";
import {Genders, UtenteConDocumentoModel} from "models/user.model";
import {UtenteConDocumentoFormData} from "screens/summingUp/screens/summingUpAddBooker/types";
import {UtenteConDocumentoFields, VeriffExtra} from "./types";
import {veriffMapperUtils} from "service/mappers/veriff/verifMapper.utils";
import {CheckInService} from "service/checkIn.service";

const {
    isResidenteInItalia,
    setLuogoRilascioDocumento,
    setComuneRilascioDocumento,
    setComuneDiResidenza,
    setLuogoDiNascita,
    setNazioneDiResidenza,
    setStatoNascita,
    setNazionalita,
    fetchStateTranslation,
    fetchCitySearch
} = veriffMapperUtils

export const veriffAnalysisMapper = async (analysis: VeriffResponse) => {
    let userAnalysisResult: UtenteConDocumentoModel = UtenteConDocumentoFormData()
    const completedFields: UtenteConDocumentoFields[] = []
    let extraFields: VeriffExtra = {}

    const person = analysis.data.verification.person;
console.log('person', person)
    if (person?.firstName?.value) {
        userAnalysisResult.nome = person?.firstName?.value.replace('-', ' ')
        completedFields.push('nome');
    }

    if (person?.lastName?.value) {
        userAnalysisResult.cognome = person?.lastName?.value.replace('-', ' ')
        completedFields.push('cognome');
    }

    if (person?.dateOfBirth) {
        userAnalysisResult.dataDiNascita = person?.dateOfBirth?.value
        completedFields.push('dataDiNascita');
    }


    if (person?.address?.value) {
        extraFields.indirizzo = person?.address?.value
    }

    if (person?.nationality) {
        const cityMetadata = await fetchStateTranslation(person?.nationality?.value)
        if (cityMetadata) {

            userAnalysisResult = setNazionalita(userAnalysisResult, cityMetadata)
            completedFields.push('nazionalita');

            //dati non presenti in nuova versione veriff
            // if (cityMetadata.descrizione === 'ITALIA' && person?.placeOfBirth) {
            //     const city = await fetchCitySearch(person?.placeOfBirth)
            //     if (city) {
            //         userAnalysisResult = setLuogoDiNascita(userAnalysisResult, city)
            //         completedFields.push('luogoDiNascita');
            //     }
            // }
        }

    }

    if (person?.gender) {
        userAnalysisResult.sesso = person?.gender?.value === 'F' ? Genders.FEMALE : Genders.MALE
        completedFields.push('sesso');
    }

    const document = analysis.data.verification.document;

    if (document?.number) {
        userAnalysisResult.numeroDocumento = document?.number?.value
        completedFields.push('numeroDocumento');
    }

    if (document?.validUntil) {
        userAnalysisResult.scadenzaDocumento = document?.validUntil?.value
        completedFields.push('scadenzaDocumento');
    }

    if (document?.type) {
        const documentType = veriffDocumentTypeMapper(document?.type?.value)
        const documentTypeProtale = veriffDocumentTypePortaleMapper(document?.type?.value)
        if (documentType && documentTypeProtale) {
            userAnalysisResult.tipoDocumento = documentType
            userAnalysisResult.tipoDocumentoPortale = documentTypeProtale
            completedFields.push('tipoDocumento');
            completedFields.push('tipoDocumentoPortale');
        } else {
            const localDocumentType = CheckInService.getDocumentType()
            if (localDocumentType) {
                userAnalysisResult.tipoDocumento = localDocumentType.tipoDocumento
                userAnalysisResult.tipoDocumentoPortale = localDocumentType.tipoDocumentoPortale
                completedFields.push('tipoDocumento');
                completedFields.push('tipoDocumentoPortale');
            }
        }
        if (person?.idNumber?.value && document?.type?.value ==  veriffDocumentType.ID_CARD ) {
            extraFields.codiceFiscale = person?.idNumber?.value
        }
    }

    if (document?.validFrom) {
        userAnalysisResult.dataRilascioDocumento = document?.validFrom?.value
        completedFields.push('dataRilascioDocumento');
    }

    if (document?.country) {
        const cityMetadata = await fetchStateTranslation(document?.country?.value)
        if (cityMetadata) {
            userAnalysisResult = setLuogoRilascioDocumento(userAnalysisResult, cityMetadata)
            completedFields.push('luogoRilascioDocumento');
            userAnalysisResult = setNazioneDiResidenza(userAnalysisResult, cityMetadata)
            completedFields.push('nazioneDiResidenza');
        }
    }
    //dati non presenti in nuova versione veriff

    // if (document?.issuedBy) {
    //     const cityMetadata = await fetchCitySearch(document?.issuedBy)
    //     if (cityMetadata) {
    //         userAnalysisResult = setComuneRilascioDocumento(userAnalysisResult, cityMetadata)
    //         completedFields.push('comuneRilascioDocumento');
    //         if (isResidenteInItalia(userAnalysisResult)) {
    //             userAnalysisResult = setComuneDiResidenza(userAnalysisResult, cityMetadata)
    //             completedFields.push('comuneDiResidenza');
    //         }
    //     }
    // }
    //

    return {
        userAnalysisResult,
        completedFields,
        extraFields,
    }
}

const veriffDocumentTypeMapper = (documentType?: string): string | undefined => {
    if (!documentType) return undefined

    if (documentType == veriffDocumentType.PASSPORT) {
        return "PASSAPORTO ORDINARIO"
    }

    if (documentType == veriffDocumentType.ID_CARD) {
        return "CARTA IDENTITA\' ELETTRONICA"
    }

    if (documentType == veriffDocumentType.DRIVERS_LICENSE) {
        return "PATENTE DI GUIDA"
    }
}


const veriffDocumentTypePortaleMapper = (documentType?: string): string | undefined => {
    if (!documentType) return undefined

    if (documentType == veriffDocumentType.PASSPORT) {
        return "PASOR"
    }

    if (documentType == veriffDocumentType.ID_CARD) {
        return "IDELE"
    }

    if (documentType == veriffDocumentType.DRIVERS_LICENSE) {
        return "PATEN"
    }
}
