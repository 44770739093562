import {simpleDataValidation} from 'utils/validation.utils';
import {DateManager} from '../../../../utils/dateFnsUtils';
import {number, object, string} from 'yup';

export const UtenteConDocumentoValidationSchema = object({
    nome: string().required('common:VALIDATION:NOME:REQUIRED').min(2, 'common:VALIDATION:NOME:IS_TO_SHORT').max(30, 'common:VALIDATION:NOME:IS_TO_LONG').trim().test('Controllo Validita', 'common:VALIDATION:NOT_VALID', simpleDataValidation),
    cognome: string().required('common:VALIDATION:COGNOME:REQUIRED').min(2, 'common:VALIDATION:COGNOME:IS_TO_SHORT').max(50, 'common:VALIDATION:COGNOME:IS_TO_LONG').trim().test('Controllo Validita', 'common:VALIDATION:NOT_VALID', simpleDataValidation),

    dataDiNascita: string().required('common:VALIDATION:DATA_DI_NASCITA:REQUIRED').test('Controllo data', 'common:VALIDATION:DATA_DI_NASCITA:IS_NOT_VALID', (value) => {
        return DateManager.isDateBeforeYears(value, 10)
    }),

    statoNascita: string().required('common:VALIDATION:STATO_NASCITA:REQUIRED'),
    statoNascitaPortale: string(),
    nazionalita: string().required('common:VALIDATION:STATO_NASCITA:REQUIRED'),
    nazionalitaPortale: string(),

    // Solo italia
    luogoDiNascita: string().when('statoNascita', {
        is: 'ITALIA',
        then: schema => schema.required('common:VALIDATION:LUOGO_DI_NASCITA:REQUIRED')
    }),
    luogoDiNascitaPortale: string(),
    comuneDiResidenza: string().when('nazioneDiResidenza', {
        is: 'ITALIA',
        then: schema => schema.required('common:VALIDATION:COMUNE_DI_RESIDENZA:REQUIRED')
    }),
    comuneDiResidenzaPortale: string(),
    //End Solo italia

    sesso: number().required('common:VALIDATION:SESSO:REQUIRED'),
    tipoAlloggiato: number(),

    nazioneDiResidenza: string().required('common:VALIDATION:NAZIONE_DI_RESIDENZA:REQUIRED'),
    nazioneDiResidenzaPortale: string(),

    numeroDocumento: string().required('common:VALIDATION:NUMERO_DOCUMENTO:REQUIRED').min(3, 'common:VALIDATION:NUMERO_DOCUMENTO:IS_TO_SHORT').max(20, 'common:VALIDATION:NUMERO_DOCUMENTO:IS_TO_LONG').trim(),

    tipoDocumento: string().required('common:VALIDATION:TIPO_DOCUMENTO:REQUIRED'),
    tipoDocumentoPortale: string(),

    scadenzaDocumento: string().required('common:VALIDATION:SCADENZA_DOCUMENTO:REQUIRED').test('Controllo data', 'common:VALIDATION:SCADENZA_DOCUMENTO:IS_NOT_VALID', (value) => {
        return DateManager.isBeforeToday(value)
    }),


    dataRilascioDocumento: string().test('Controllo data', 'common:VALIDATION:DATA_RILASCIO_DOCUMENTO:IS_NOT_VALID', (value) => {
        return DateManager.isAfterToday(value)
    }),

    luogoRilascioDocumento: string().required('common:VALIDATION:LUOGO_RILASCIO_DOCUMENTO:REQUIRED'),
    luogoRilascioDocumentoPortale: string(),

    comuneRilascioDocumento: string().when('luogoRilascioDocumento', {
        is: 'ITALIA',
        then: schema => schema.required('common:VALIDATION:COMUNE_RILASCIO_DOCUMENTO:REQUIRED')
    }),
    comuneRilascioDocumentoPortale: string(),

    fotoDocumentoFronte: string(),
    fotoDocumentoRetro: string(),
    fotoSelfie: string(),
});
