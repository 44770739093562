import React, {FC} from 'react';
import {Flex} from "@chakra-ui/react";
import {borderRadius} from "../../../style/styleConstants";
import {appThemeColors} from "../../../style/chakraTheme";
import {IconType} from "react-icons";

interface MySquareIconProps {
    icon: IconType,
    backgroundColor?: string,
    color?: string,
    size?: number,
    onClick?: () => void,
}

export const MySquareIcon: FC<MySquareIconProps> = (props) => {
    const SquareIcon = props.icon;  // note the capital first letter!

    const bgSize = props.size ? props.size * 1.5 : 50

    return (
        <Flex align={'center'} justify={'center'} onClick={props.onClick} h={bgSize} mr={5} w={bgSize}
              borderRadius={borderRadius * 2}
              backgroundColor={props.backgroundColor ?? 'primary.100'}>
            <SquareIcon size={props.size ?? 30} color={props.color ?? appThemeColors.primary["500"]}/>
        </Flex>
    )
};
