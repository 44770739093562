import {configureStore} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {authReducer} from 'store/auth/auth.reducer';
import {checkinReducer} from 'store/checkin/checkin.reducer';
import {settingsReducer} from 'store/settings/settings.reducer';
import {autocompleteReducer} from 'store/autocomplete/autocomplete.reducer';
import {uiManagerReducer} from 'store/uiManager/uiManager.reducer';
import Interceptor from 'service/interceptor/auth.interceptor';
import {postPrenotazioneReducer} from 'store/postPrenotazione/postPrenotazione.reducer';

export const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
    reducer: {
        //Add yours reducers
        authReducer,
        checkinReducer,
        settingsReducer,
        autocompleteReducer,
        uiManagerReducer,
        postPrenotazioneReducer
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type RootStore = typeof store
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
Interceptor.interceptor(store);
