import {createAsyncThunk} from '@reduxjs/toolkit';
import {uiManagerActions} from 'store/uiManager/uiManager.action';
import {PostPrenotazioneService} from 'service/postPrenotazione.service';
import {VarcoModel} from 'models/varco.model';
import {ProceduraModel} from 'models/procedura.model';
import {LinkModel} from 'models/link.model';
import {GeolocationModel} from 'models/geolocation.model';


export const enum POST_PRENOTAZIONE_ACTION {
    FETCH_VARCHI = 'FETCH_VARCHI',
    FETCH_PROCEDURE = 'FETCH_PROCEDURE',
    FETCH_LINK = 'FETCH_LINK',
    GET_PIN_VARCO = 'GET_PIN_VARCO',
    OPEN_VARCO = 'OPEN_VARCO',
    NOTIFICA_APERTURA = 'NOTIFICA_APERTURA',
}

const fetchVarchi = createAsyncThunk<VarcoModel[]>(POST_PRENOTAZIONE_ACTION.FETCH_VARCHI, async (params: any, thunkAPI) => {
    try {
        return await PostPrenotazioneService.fetchVarchi()
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'common:ERRORS:GENERIC:TITLE',
            description: 'common:ERRORS:GENERIC:DESCRIPTION',
            duration: 3000,
            status: 'error',
            isI18nKey: true
        }));
        throw e
    }
});

const fetchProcedure = createAsyncThunk<ProceduraModel[]>(POST_PRENOTAZIONE_ACTION.FETCH_PROCEDURE, async (params: any, thunkAPI) => {
    try {
        return await PostPrenotazioneService.fetchProcedure()
    } catch (e: any) {
        throw e
    }
});

const fetchLink = createAsyncThunk<LinkModel[]>(POST_PRENOTAZIONE_ACTION.FETCH_LINK, async (params: any, thunkAPI) => {
    try {
        return await PostPrenotazioneService.fetchLink()
    } catch (e: any) {
        throw e
    }
});

const getPinVarco = createAsyncThunk(POST_PRENOTAZIONE_ACTION.GET_PIN_VARCO, async (id: number, thunkAPI) => {
    try {
        return await PostPrenotazioneService.getPinVarco(id)
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'common:ERRORS:GENERIC:TITLE',
            description: 'common:ERRORS:GET_PIN_VARCO',
            duration: 3000,
            isClosable: true,
            status: 'error',
            isI18nKey: true
        }));
        throw e
    }
});

const openVarco = createAsyncThunk(POST_PRENOTAZIONE_ACTION.OPEN_VARCO, async ({
                                                                                   id,
                                                                                   geolocation,
                                                                                   force
                                                                               }: { id: number, geolocation: GeolocationModel, force:boolean }, thunkAPI) => {
    try {
        const isOpenSuccessfull = await PostPrenotazioneService.openVarco(id, geolocation,force)
        if (isOpenSuccessfull) {
            thunkAPI.dispatch(uiManagerActions.showToast({
                title: 'common:TEXTS:SUCCESS_OPEN_VARCO:TITLE',
                description: 'common:TEXTS:SUCCESS_OPEN_VARCO:SUBTITLE',
                duration: 3000,
                status: 'success',
                isClosable: true,
                isI18nKey: true
            }));
            return true
        }else{
            return false
        }
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'common:ERRORS:GENERIC:TITLE',
            description: 'common:ERRORS:OPEN_VARCO:DESCRIPTION',
            duration: 3000,
            status: 'error',
            isClosable: true,
            isI18nKey: true
        }));
        throw e
    }
});

const notificaApertura = createAsyncThunk(POST_PRENOTAZIONE_ACTION.NOTIFICA_APERTURA, async () => {
    try {
        await PostPrenotazioneService.notificaApertura()
    } catch (e: any) {
        console.log('notificaApertura error', e)
    }
})

export const postPrenotazioneActions = {
    fetchVarchi,
    fetchProcedure,
    fetchLink,
    getPinVarco,
    openVarco,
    notificaApertura
}
