import {createReducer} from '@reduxjs/toolkit';
import {PostPrenotazioneState} from 'store/postPrenotazione/types';
import {postPrenotazioneActions} from 'store/postPrenotazione/postPrenotazione.action';

const initialState: PostPrenotazioneState = {
    isLoading: false,
    isError: false,
    isErrorProcedure: false,
    isErrorLink: false,
};

export const postPrenotazioneReducer = createReducer(initialState, (builder) => {

    builder.addCase(postPrenotazioneActions.openVarco.rejected, (state, action) => {
        return {
            ...state,
        }
    });
    builder.addCase(postPrenotazioneActions.openVarco.fulfilled, (state, action) => {
        return {
            ...state,
            multipleOpening: 0
        }
    });

    builder.addCase(postPrenotazioneActions.fetchVarchi.pending, (state, action) => {
        return {
            ...state,
            isLoading: true,
            isError: false,
        }
    });
    builder.addCase(postPrenotazioneActions.fetchVarchi.fulfilled, (state, action) => {
        if (action.payload) {
            return {
                ...state,
                varchi: [...action.payload],
                isLoading: false,
                isError: false
            }
        }
        return state

    });
    builder.addCase(postPrenotazioneActions.fetchVarchi.rejected, (state, action) => {
        return {
            ...state,
            isError: true,
            isLoading: false,
            prenotazione: undefined
        }
    });

    builder.addCase(postPrenotazioneActions.fetchProcedure.pending, (state, action) => {
        return {
            ...state,
            isLoading: true,
            isErrorProcedure: false,
        }
    });
    builder.addCase(postPrenotazioneActions.fetchProcedure.fulfilled, (state, action) => {
        if (action.payload) {
            return {
                ...state,
                procedure: [...action.payload],
                isLoading: false,
                isErrorProcedure: false
            }
        }
        return state

    });
    builder.addCase(postPrenotazioneActions.fetchProcedure.rejected, (state, action) => {
        return {
            ...state,
            isErrorProcedure: true,
            isLoading: false,
            prenotazione: undefined
        }
    });

    builder.addCase(postPrenotazioneActions.fetchLink.pending, (state, action) => {
        return {
            ...state,
            isLoading: true,
            isErrorLink: false,
        }
    });
    builder.addCase(postPrenotazioneActions.fetchLink.fulfilled, (state, action) => {
        if (action.payload) {
            return {
                ...state,
                linkUtili: [...action.payload],
                isLoading: false,
                isErrorLink: false
            }
        }
        return state

    });
    builder.addCase(postPrenotazioneActions.fetchLink.rejected, (state, action) => {
        return {
            ...state,
            isErrorLink: true,
            isLoading: false,
            prenotazione: undefined
        }
    });

    builder.addDefaultCase((state, action) => {
        return state;
    });

});
