import React from 'react';
import {Center, Spinner, Stack, Text, VStack} from '@chakra-ui/react';
import {HeaderComponent} from 'shared/components/header/header.component';
import {ReactComponent as WelcomeMobile} from 'assets/general/welcome_mobile.svg';
import {useTranslation} from 'react-i18next';

export const DeviceNotSupportedPage = () => {
    const {t,ready} = useTranslation();

    if (ready === false) {
        return (
            <Center h={'100vh'} w={'full'}>
                <Spinner/>
            </Center>
        )
    }

    return (
        <VStack minH={'100vh'} backgroundColor={'background'} overflow={'hidden'}>
            <HeaderComponent pageName={''}/>
            <Center pt={60} h={'full'} m={6}>
                <Stack direction={{base:'column-reverse',lg:'row'}}  align={'start'}>
                    <VStack h={'full'} align={'start'} marginX={6}>
                        <Text color={'primary.500'} fontWeight={'bold'}
                              fontSize={'xx-large'}>{t('common:TEXTS:DESKTOP_LAYOUT:TITLE')}</Text>
                        <Text color={'titleColor'} marginX={6} fontWeight={'semibold'}
                              fontSize={'x-large'}>{t('common:TEXTS:DESKTOP_LAYOUT:SUBTITLE')}</Text>
                        <Text color={'titleColor'} marginX={6}
                              fontSize={'large'}>{t('common:TEXTS:DESKTOP_LAYOUT:DESCRIPTION')}</Text>
                    </VStack>
                    <WelcomeMobile style={{width:'60%',height:'60%'}} />
                </Stack>
            </Center>
        </VStack>
    );
};
