import React, {FC, ReactElement} from 'react';
import {Select} from 'antd';
import {selectAutoCompleteStyle} from 'style/styleConstants';
import 'index.css'

export const {Option} = Select;

interface SelectAutocompleteProps<T> {
    placeholder: string;
    id?: string;
    value: any;
    itemRef: any;
    itemList: T[];
    noFoundContent?: React.ReactNode;
    onChange: (value: T) => void;
    renderItem: (item: T) => ReactElement<typeof Option>;
    onSearch?: (value: string) => void;
    withoutSearch?: boolean;
    isDisabled?: boolean;
}

export const SelectAutocomplete: FC<SelectAutocompleteProps<any>> = (props) => {


    return (
        <Select
            id={props.id}
            key={props.id}
            showAction={['focus', 'click']}
            showSearch={!props.withoutSearch}
            placeholder={props.placeholder}
            disabled={props.isDisabled}
            optionFilterProp="children"
            ref={props.itemRef}
            value={props.value==='' ? undefined : props.value}
            onChange={props.onChange}
            onSearch={props.onSearch}
            style={selectAutoCompleteStyle}
            getPopupContainer={trigger => trigger.parentNode}
            filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            size={'large'}
            bordered={true}
            notFoundContent={props.noFoundContent}
            dropdownStyle={selectAutoCompleteStyle}
            virtual={false}
        >
            {props.itemList.map((item) => (
                props.renderItem(item)
            ))}
        </Select>

    );
};
