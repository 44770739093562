import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import enTexts from 'screens/summingUp/i18n/en.json';
import itTexts from 'screens/summingUp/i18n/it.json';
import {loadTranslations} from 'i18n/i18n.service';
import {RoutesPaths} from 'navigation/routes';
import {useHistory} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {uiManagerActions} from 'store/uiManager/uiManager.action';
import {checkinActions} from 'store/checkin/checkin.action';
import {UtenteConDocumentoModel} from 'models/user.model';
import {setPropertiesForUtenteConDocumento} from 'utils/checkin.utils';
import MyPage from 'shared/components/defaultComponents/myPage/myPage.component';
import {VeriffService} from 'service/veriff.service';
import {veriffAnalysisMapper} from "service/mappers/veriff/veriff.mapper";
import {UtenteConDocumentoFields} from "service/mappers/veriff/types";

export const DocumentScanningAnalysis = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);

    const i18nTexts = {
        'en': enTexts,
        'it': itTexts,
    };
    loadTranslations('summingUpPage', i18nTexts)
    const {stepNumber, prenotazione, utils} = useAppSelector(state => state.checkinReducer);

    useEffect(() => {
        try {

            const sessionId = VeriffService.getVeriffSessionId()
            if (sessionId) {
                handleFetchResponse(sessionId)
            } else {
                throw new Error('Error no session ID')
            }
        } catch (e) {
            console.log('Error fetching scan response: ', e)
            dispatch(uiManagerActions.showToast({
                title: t('common:ERRORS:SIMPLE_ERROR'),
                description: t('summingUpPage:ERRORS:SCAN_ANALYSIS'),
                status: 'error',
            }))
        }
    }, []);

    const handleFetchResponse = async (sessionId: string) => {
        try {
            const response = await VeriffService.fetchVeriffResponse(sessionId);
            const resultAnalysis = response.veriffResponse
            if (resultAnalysis) {
                const analysisMappingResult = await veriffAnalysisMapper(resultAnalysis);
                console.log('analysisMappingResult: ', analysisMappingResult)
                await saveUserFromAnalysis(analysisMappingResult.userAnalysisResult, analysisMappingResult.completedFields);
                dispatch(checkinActions.saveVeriffExtra(analysisMappingResult.extraFields));
                console.log('Mappin riuscito: ', analysisMappingResult.userAnalysisResult)
            } else {
                console.log('Nessuna analisi trovata')
                throw new Error('Error no result analysis')
            }
        } catch (e) {
            console.log('Error fetching scan response: ', e)
            dispatch(uiManagerActions.showToast({
                title: t('common:ERRORS:SIMPLE_ERROR'),
                description: t('summingUpPage:ERRORS:SCAN_ANALYSIS'),
                status: 'error',
            }))
        } finally {
            handleNextPage()
            setIsLoading(false)
        }
    }

    const saveUserFromAnalysis = async (utente: UtenteConDocumentoModel, completedFields: UtenteConDocumentoFields[]) => {
        dispatch(checkinActions.saveUtenteCapoGruppo({
            utenteCapoGruppo: setPropertiesForUtenteConDocumento(utente, prenotazione?.numeroUtentiOspite, '', '', utils?.documentType),
            stepNumber: stepNumber === 0 ? 1 : stepNumber, // go from 0 to 1 because we added the booker, if the step number is higher it means that we are editing the user
            completedFields
        }))
        if (completedFields.length === 0) {
            dispatch(uiManagerActions.showToast({
                title: t('common:ERRORS:SIMPLE_ERROR'),
                description: t('summingUpPage:ERRORS:SCAN_ANALYSIS'),
                status: 'error',
            }))
        } else if (completedFields.length >= 7) { // at least half of the fields
            dispatch(uiManagerActions.showToast({
                title: t('summingUpPage:TEXTS:DOCUMENT_SCANNING_SUCCESS_TITLE'),
                description: t('summingUpPage:TEXTS:DOCUMENT_SCANNING_SUCCESS_SUBTITLE'),
                status: 'success',
            }))
        } else {
            dispatch(uiManagerActions.showToast({
                title: t('common:ERRORS:SIMPLE_ERROR'),
                description: t('summingUpPage:ERRORS:PARTIAL_SCAN_ANALYSIS'),
                status: 'warning',
            }))
        }
    }

    const handleNextPage = () => {
        history.push(RoutesPaths.SUMMINGUP_ADDBOOKER.toString())
    }

    return (
        <MyPage header={{pageName: ''}}
                isLoading={isLoading}>
        </MyPage>
    );
};
