import {CityStateMetadata} from "service/mappers/veriff/types";
import {store} from "store/store.config";
import {autocompleteActions} from "store/autocomplete/autocomplete.action";
import {CityModel} from "models/city.model";
import {StateModel} from "models/state.model";
import {UtenteConDocumentoModel} from "models/user.model";

const fetchCitySearch = async (cityNameParsed: string): Promise<CityStateMetadata | undefined> => {
    return await store.dispatch(autocompleteActions.fetchCitiesSearch(cityNameParsed)).then((res) => {
        const resCities = res.payload as CityModel[]
        if (resCities.length > 0) {
            // Search City and normalise the texts to uppercase
            const cityFind = resCities.find(c => c.descrizione.toUpperCase().replace(/\s/g, '') === cityNameParsed.toUpperCase().replace(/\s/g, ''))
            const city = cityFind ? cityFind : resCities[0]

            return {
                codice: city.codice,
                descrizione: city.descrizione,
                provincia: city.provincia
            }
        }
    })
}

const fetchStateTranslation = async (stateName: string): Promise<CityStateMetadata | undefined> => {
    return await store.dispatch(autocompleteActions.fetchStateTranslation(stateName)).then((res) => {
        const state = res.payload as StateModel
        if (state && state.codice && state.descrizione) {
            return {
                codice: state.codice,
                descrizione: state.descrizione
            }
        }
    })
}

const isResidenteInItalia = (utente: UtenteConDocumentoModel) => {
    return (utente.nazioneDiResidenza === 'ITALIA')
}

const setComuneDiResidenza = (utente: UtenteConDocumentoModel, city?: CityStateMetadata) => {
    if (city) {
        utente.comuneDiResidenza = city.descrizione
        utente.comuneDiResidenzaPortale = city.codice
    }

    return utente
}

const setComuneRilascioDocumento = (utente: UtenteConDocumentoModel, city?: CityStateMetadata) => {
    if (city) {
        utente.comuneRilascioDocumento = city.descrizione
        utente.comuneRilascioDocumentoPortale = city.codice
    }

    return utente
}

const setLuogoRilascioDocumento = (utente: UtenteConDocumentoModel, state?: CityStateMetadata) => {
    if (state) {
        utente.luogoRilascioDocumento = state.descrizione
        utente.luogoRilascioDocumentoPortale = state.codice
    }

    return utente
}

const setNazioneDiResidenza = (utente: UtenteConDocumentoModel, state?: CityStateMetadata) => {
    if (state) {
        utente.nazioneDiResidenza = state.descrizione
        utente.nazioneDiResidenzaPortale = state.codice
    }

    return utente
}

const setStatoNascita = (utente: UtenteConDocumentoModel, state?: CityStateMetadata) => {
    if (state) {
        utente.statoNascita = state.descrizione
        utente.statoNascitaPortale = state.codice
    }

    return utente
}

const setNazionalita = (utente: UtenteConDocumentoModel, state?: CityStateMetadata) => {
    if (state) {
        utente.nazionalita = state.descrizione
        utente.nazionalitaPortale = state.codice
    }

    return utente
}

const setLuogoDiNascita = (utente: UtenteConDocumentoModel, city?: CityStateMetadata) => {
    if (city) {
        utente.luogoDiNascita = city.descrizione
        utente.luogoDiNascitaPortale = city.codice
    }

    return utente
}


export const veriffMapperUtils = {
    fetchCitySearch,
    fetchStateTranslation,
    isResidenteInItalia,
    setComuneDiResidenza,
    setComuneRilascioDocumento,
    setLuogoRilascioDocumento,
    setNazioneDiResidenza,
    setStatoNascita,
    setNazionalita,
    setLuogoDiNascita
}
