import React, {FC} from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button
} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {SettingsActions} from 'store/settings/settings.action';

export interface AlertProviderProps {

}

export const AlertProvider: FC<AlertProviderProps> = (props) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const alert = useAppSelector(state => state.settingsReducer.alert);
    const cancelRef = React.useRef<any>()
    const [isLoading, setIsLoading] = React.useState(false)

    const handleOnClose = () => {
        dispatch(SettingsActions.showAlert())
    }

    const handleConfirm = async () => {
        if (alert?.onConfirm){
            setIsLoading(true)
            await alert?.onConfirm();
            setIsLoading(false)
        }
        dispatch(SettingsActions.showAlert())
    }

    return (
        <>
            <AlertDialog
                isOpen={!!alert}
                leastDestructiveRef={cancelRef}
                onClose={handleOnClose}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {alert?.title}
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            {alert?.body()}
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            {alert?.customCancelText &&
                            <Button ref={cancelRef} onClick={handleOnClose}>
                                {alert.customCancelText}
                            </Button>
                            }
                            <Button colorScheme="primary" isLoading={isLoading} ml={3} onClick={handleConfirm}>
                                {alert?.customConfirmText ? alert.customConfirmText : t('common:BUTTONS:CONFIRM')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {props.children}
        </>
    );
};
