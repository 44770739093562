import {createAction, createAsyncThunk} from '@reduxjs/toolkit';
import {defaultLanguage, setUserSelectedLanguage, SUPPORTED_LANGUAGES} from 'i18n/i18n.config';
import {SetLanguageParams, ShowAlertParams} from 'store/settings/types';
import {uiManagerActions} from 'store/uiManager/uiManager.action';
import {SettingsService} from 'service/setting.service';

export const enum SETTINGS_ACTION {
    SET_LANGUAGE = 'settings/SET_LANGUAGE',
    GET_SUPPORT_NUMBER = 'settings/GET_SUPPORT_NUMBER',
    SHOW_ALERT = 'settings/SHOW_ALERT',
}

const showAlert = createAction<ShowAlertParams|undefined>(SETTINGS_ACTION.SHOW_ALERT);

const setLanguageSetting = createAction(SETTINGS_ACTION.SET_LANGUAGE, (params:SetLanguageParams) => {
    setUserSelectedLanguage(params.language);
    switch (params.language){
        case SUPPORTED_LANGUAGES.EN:
            return { payload: params}
        case SUPPORTED_LANGUAGES.IT:
            return { payload: params}
        default:
            return {
                payload: {
                    language: defaultLanguage,
                    overrideServer: true
                }
            }
    }
})

const getSupportNumber = createAsyncThunk<string>(SETTINGS_ACTION.GET_SUPPORT_NUMBER, async (params:any, thunkAPI) => {
    try{
        return await SettingsService.getSupportNumberRequest()
    }catch (e:any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'common:ERRORS:SUPPORT_NUMBER:TITLE',
            description: 'common:ERRORS:SUPPORT_NUMBER:DESCRIPTION',
            duration: 5000,
            status: 'error',
            isClosable: true,
            isI18nKey: true
        }));
        throw e
    }
});


export const SettingsActions = {
    setLanguageSetting,
    getSupportNumber,
    showAlert
}
