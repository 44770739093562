import React, {FC} from 'react';
import {Box, Divider, GridItem, Text, VStack} from "@chakra-ui/react";
import {MySquareIcon} from "../../MySquareIcon/mySquareIcon.component";
import {IoLocation, IoPeople } from "react-icons/io5";
import {MdOutlineMeetingRoom, MdStairs} from "react-icons/md";
import MyButton from "../../defaultComponents/myButton/myButton.component";
import {MenuLanguageComponent} from "../../../../i18n/component/menuLanguage.component";
import {StrutturaModel} from "../../../../models/struttura.model";
import {useTranslation} from "react-i18next";

export interface TableSummingUpComponentProps {
    numeroUtentiOspite?: number,
    struttura: StrutturaModel
    handleDocumentSelection?: () => void,
}

export const TableSummingUpComponent: FC<TableSummingUpComponentProps> = ({
                                                                              struttura,
                                                                              numeroUtentiOspite,
                                                                              handleDocumentSelection
                                                                          }) => {
    const {t} = useTranslation();
    const {piano, strutturaIndirizzo} = struttura;

    return (
        <>
            {/* Divider*/}
            <GridItem rowSpan={1} colSpan={4}>
                <Divider/>
            </GridItem>

            {/* Line 3*/}
            <GridItem rowSpan={1} colSpan={1}>
                <VStack justify={'center'} align={'start'} h={"full"}>
                    <MySquareIcon icon={IoLocation}/>
                </VStack>
            </GridItem>
            <GridItem rowSpan={1} colSpan={3}>
                <VStack align='start' justify={'center'} h={"full"}>
                    <Text fontSize={'md'} color={'titleColor'}
                          fontWeight={'normal'}>{t('summingUpPage:TEXTS:ADDRESS')}</Text>
                    <Text fontSize={'md'} my={1} fontWeight={'light'}
                          color={'subtitleColor'}>{`${strutturaIndirizzo?.citta}, ${strutturaIndirizzo?.indirizzo}`}</Text>
                </VStack>
            </GridItem>

            {/* Line 4 */}
            {piano && piano != '' && (
                <>
                    <GridItem rowSpan={1} colSpan={1}>
                        <VStack justify={'center'} align={'start'} h={"full"}>
                            <MySquareIcon icon={MdStairs}/>
                        </VStack>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={3}>
                        <VStack align='start' justify={'center'} h={"full"}>
                            <Text fontSize={'md'} color={'titleColor'}
                                  fontWeight={'normal'}>{t('summingUpPage:TEXTS:FLOOR')}</Text>
                            <Text fontSize={'md'} my={1} fontWeight={'light'}
                                  color={'subtitleColor'}>{piano}</Text>
                        </VStack>
                    </GridItem>
                </>
            )}
            {/* Line 5 */}
            {strutturaIndirizzo?.interno && strutturaIndirizzo.interno != '' && (
                <>
                    <GridItem rowSpan={1} colSpan={1}>
                        <VStack justify={'center'} align={'start'} h={"full"}>
                            <MySquareIcon icon={MdOutlineMeetingRoom}/>
                        </VStack>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={3}>
                        <VStack align='start' justify={'center'} h={"full"}>
                            <Text fontSize={'md'} color={'titleColor'}
                                  fontWeight={'normal'}>{t('summingUpPage:TEXTS:ROOM_NUMBER')}</Text>
                            <Text fontSize={'md'} my={1} fontWeight={'light'}
                                  color={'subtitleColor'}>{strutturaIndirizzo?.interno}</Text>
                        </VStack>
                    </GridItem>
                </>
            )}

            {/* Line 6*/}
            <GridItem rowSpan={1} colSpan={1}>
                <VStack justify={'center'} align={'start'} h={"full"}>
                    <MySquareIcon icon={IoPeople}/>
                </VStack>
            </GridItem>
            <GridItem rowSpan={1} colSpan={3}>
                <VStack align='start' justify={'center'} h={"full"}>
                    <Text fontSize={'md'} color={'titleColor'}
                          fontWeight={'normal'}>{t('summingUpPage:TEXTS:NUMBER_OF_PEOPLE')}</Text>
                    <Text fontSize={'md'} my={1} fontWeight={'light'}
                          color={'subtitleColor'}>{numeroUtentiOspite}</Text>
                </VStack>
            </GridItem>

            {/* Next Button*/}
            {handleDocumentSelection &&
                <GridItem rowSpan={2} colSpan={4}>
                    <Box w={'full'} h={"full"} position={'relative'}>
                        <MyButton myType={'PRIMARY'} my={4}
                                  onClick={handleDocumentSelection}>{t('common:BUTTONS:CONTINUE')}</MyButton>
                        <MenuLanguageComponent/>
                    </Box>
                </GridItem>}
        </>
    );
};
