import {defaultLanguage, i18Mfe} from './i18n.config';


const loadDefaultLanguage = (namespace: string, i18nTexts: any): void => {
    if(i18Mfe.isInitialized){
        if (!i18Mfe.hasResourceBundle(defaultLanguage, namespace)) {
            i18Mfe.addResourceBundle(defaultLanguage, namespace, i18nTexts[defaultLanguage]);
        }
    }
};


export const loadTranslations = (namespace: string, i18nTexts: any): void => {
    loadDefaultLanguage(namespace, i18nTexts);
    if(i18Mfe.isInitialized) {
        if (!i18Mfe.hasResourceBundle(getCurrentLanguage(), namespace)) {
            i18Mfe.addResourceBundle(getCurrentLanguage(), namespace, i18nTexts[getCurrentLanguage()]);
        }
    }
};

export const overwriteTranslations = (namespace: string, i18nTexts: any) => {
    if(i18Mfe.isInitialized) {
        if (i18Mfe.hasResourceBundle(getCurrentLanguage(), namespace)) {
            i18Mfe.addResourceBundle(getCurrentLanguage(), namespace, i18nTexts[getCurrentLanguage()], true, true);
        } else {
            i18Mfe.addResourceBundle(getCurrentLanguage(), namespace, i18nTexts[getCurrentLanguage()]);
        }
    }
};

export const getCurrentLanguage = (): string => {
    if(i18Mfe.isInitialized) {
        if (i18Mfe.languages && i18Mfe.languages.length) {
            return i18Mfe.languages[0];
        }
    }
    return defaultLanguage;
};
