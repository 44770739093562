import {AxiosError, AxiosResponse} from 'axios';
import {FetchPrenotazioneResponse, SaveCheckInResponse} from 'service/types/checkin.types';
import {PrenotazioneDTO} from 'models/prenotazione.dto';
import {CheckInStatusResponse, SaveCheckinParams} from 'store/checkin/types';
import {appAxios} from 'service/axios.config';
import {prenotazioneMapper} from "./mappers/prenotazione.mapper";
import {DocumentTypeWithPortale} from "models/document.model";

const fetchBookingRequest = async (): Promise<FetchPrenotazioneResponse> => {
    const response: AxiosResponse<PrenotazioneDTO> = await appAxios.get(`/webappguest/getPrenotazione`)
    console.log('Request [fetchBookingRequest] ', response.data)
    if (response && response.data) {
        const prenotazione = response.data;
        return {
            isAuth: !!prenotazione,
            prenotazione: prenotazioneMapper(prenotazione),
            hasConfirmedBooking: !!prenotazione.dataCheckInEffettuato
        }
    }
    throw new Error('Error fetching booking')

}

const saveCheckinRequest = async (prenotazione: SaveCheckinParams): Promise<CheckInStatusResponse> => {
    return appAxios.post<SaveCheckInResponse>(`/webappguest/saveCheckIn`, prenotazione)
        .then((response) => {
            console.log('Request [saveCheckinRequest] ', response)
            if (response && response.status === 200) {
                return CheckInStatusResponse.SUCCESS
            }
            throw new Error('Error confirming booking')
        })
        .catch(error => {
            const err = error as AxiosError<SaveCheckInResponse>
            console.log('Request [saveCheckinRequest] error res: ', err.response)
            if (err?.response?.data?.checkInEffettuato == true && err.response?.status === 400) {
                return CheckInStatusResponse.ALREADY_CHECKED_IN
            }
            throw new Error('Error confirming booking')
        })
}

const getDocumentType = ():DocumentTypeWithPortale | undefined => {
    const tipoDocumento = localStorage.getItem('tipoDocumento');
    const portale = localStorage.getItem('tipoDocumentoPortale');

    if (!tipoDocumento || !portale) {
        return
    }

    return {
        tipoDocumento: tipoDocumento,
        tipoDocumentoPortale: portale
    }

}

const setDocumentType = (tipoDocumento: string,tipoDocumentoPortale:string) => {
    if (tipoDocumento && tipoDocumentoPortale) {
        localStorage.setItem('tipoDocumento', tipoDocumento);
        localStorage.setItem('tipoDocumentoPortale', tipoDocumentoPortale);
    }
}

export const CheckInService = {
    fetchBookingRequest,
    saveCheckinRequest,
    getDocumentType,
    setDocumentType
}
