import React, {useEffect, useState} from 'react';
import {Box, Checkbox, Divider, Flex, FormControl, FormLabel, Select, Stack, Text} from '@chakra-ui/react';
import MyButton from 'shared/components/defaultComponents/myButton/myButton.component';
import {MenuLanguageComponent} from 'i18n/component/menuLanguage.component';
import {useTranslation} from 'react-i18next';
import enTexts from 'screens/summingUp/i18n/en.json';
import itTexts from 'screens/summingUp/i18n/it.json';
import {loadTranslations} from 'i18n/i18n.service';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {DocumentModel} from 'models/document.model';
import {RoutesPaths} from 'navigation/routes';
import {useHistory} from 'react-router-dom';
import {checkinActions} from 'store/checkin/checkin.action';
import {uiManagerActions} from 'store/uiManager/uiManager.action';
import MyPage from 'shared/components/defaultComponents/myPage/myPage.component';
import AcceptTermsImg from 'assets/general/accept_terms.svg';
import {borderRadius} from '../../../../style/styleConstants';
import {MyImage} from '../../../../shared/components/defaultComponents/myImage/myImage.component';
import {SettingsActions} from 'store/settings/settings.action';
import {CheckInService} from "service/checkIn.service";
import {getEnvToBoolean} from "utils/utils";

export const DocumentSelection = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()

    const {t} = useTranslation();
    const i18nTexts = {
        'en': enTexts,
        'it': itTexts,
    };
    loadTranslations('summingUpPage', i18nTexts)
    const {prenotazione} = useAppSelector(state => state.checkinReducer);
    const [selectedDocument, setSelectedDocument] = useState<DocumentModel | undefined>(undefined)
    const [privacyApproval, setPrivacyApproval] = useState<boolean>(false)
    const [isPrivacyInvalid, setIsPrivacyInvalid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const documents: DocumentModel[] = [
        {
            'id': 3,
            'codice': 'IDELE',
            'descrizione': 'ELECTRONIC_ID' // i18n Key
        },
        {
            'id': 1,
            'codice': 'IDENT',
            'descrizione': 'ID' // i18n Key
        },
        {
            'id': 7,
            'codice': 'PASOR',
            'descrizione': 'PASSPORT' // i18n Key
        },
        {
            'id': 8,
            'codice': 'PATEN',
            'descrizione': 'DRIVING_LICENSE' // i18n Key
        },
        {
            'id': 0,
            'codice': 'ALTRO',
            'descrizione': 'OTHER' // i18n Key
        }
    ]

    useEffect(() => {
        setIsLoading(true)
    }, [])

    const handleAddDocument = async () => {
        const isVeriffEnabled: boolean = getEnvToBoolean(process.env.REACT_APP_IS_VERIFF_ENABLED);
        if (selectedDocument && privacyApproval) {
            setIsPrivacyInvalid(false)

           const selectedDocIta : DocumentModel ={
                id : selectedDocument.id,
                codice : selectedDocument.codice,
                descrizione : t(`summingUpPage:DOCUMENT_SELECTION:${selectedDocument.descrizione}`)
            }

            CheckInService.setDocumentType(selectedDocIta.descrizione, selectedDocument.codice)
            if (selectedDocIta.id != 0 && selectedDocIta.id != 1 && isVeriffEnabled && prenotazione?.veriffStatus != 'fail') {
                await dispatch(checkinActions.addDocumentType(selectedDocIta))
                // save to local storage
                return history.push(RoutesPaths.DOCUMENT_SCANNING.toString())
            }
            return history.push(RoutesPaths.SUMMINGUP_ADDBOOKER.toString())
        } else {
            if (selectedDocument === undefined) {
                dispatch(uiManagerActions.showToast({
                    title: t('common:ERRORS:SIMPLE_ERROR'),
                    description: t('summingUpPage:ERRORS:SELECT_DOCUMENT_TYPE'),
                    status: 'error'
                }))
            }
            if (!privacyApproval) {
                setIsPrivacyInvalid(true)
                dispatch(uiManagerActions.showToast({
                    title: t('common:ERRORS:SIMPLE_ERROR'),
                    description: t('summingUpPage:ERRORS:APPROVE_PRIVACY'),
                    status: 'error'
                }))
            }

        }
    }


    const handleOpenPrivacy = () => {
        if (process.env.REACT_APP_PRIVACY_LINK) {
            window.open(`${process.env.REACT_APP_PRIVACY_LINK}`, '_blank');
        }
    }

    const handleDocumentWarning = () => {
        dispatch(SettingsActions.showAlert({
            title: t('summingUpPage:TEXTS:DOCUMENT_WARNING'),
            body: () => {
                return (
                    <Box>
                        <Text fontSize={'md'}>
                            {t('summingUpPage:TEXTS:DOCUMENT_WARNING_PARAGRAPH')}
                        </Text>
                    </Box>
                )
            }
        }))

    }


    const getButtonText = () => {
        const isVeriffEnabled: boolean = getEnvToBoolean(process.env.REACT_APP_IS_VERIFF_ENABLED);

        if ((selectedDocument && selectedDocument.id == 0) || !isVeriffEnabled || prenotazione?.veriffStatus == 'fail') {
            return t('summingUpPage:BUTTONS:PROCEDE_WITH_DATA_ENTRY')
        }
        return t('summingUpPage:BUTTONS:PROCEDE_WITH_SCAN')
    }


    return (
        <MyPage header={{pageName: t('summingUpPage:TEXTS.SELECT_DOCUMENT')}}
                mainContainerProps={{align: 'start'}}>
            <Box w={'full'} px={3}>
                <Stack top={3} w={'full'} justify={'center'} align={'center'}>
                    <Flex w={'full'} align={'start'}>
                        <Text fontSize={'x-large'} color={'titleColor'}
                              align={'start'}
                              position={'relative'}
                              top={5} left={0}
                              zIndex={20}
                              fontWeight={'bold'}>{t('summingUpPage:TEXTS.DOCUMENT_SELECTION_TITLE')}</Text>
                    </Flex>
                    <MyImage src={AcceptTermsImg} style={{height: 200, width: '100%'}}/>
                </Stack>
                <Text fontSize={'md'}
                      mt={2}
                      color={'subtitleColor'}>{t('summingUpPage:TEXTS.DOCUMENT_SELECTION_SUBTITLE')}</Text>
                <Box h={5}/>
                <FormControl>
                    <FormLabel color={'titleColor'}>{t('common:USER_DATA:TIPO_DOCUMENTO')}</FormLabel>
                    <Select placeholder={t('common:USER_DATA:TIPO_DOCUMENTO')}
                            borderColor={'borderColor'}
                            borderRadius={borderRadius}
                            onChange={(e) => {
                        const documento = documents.find(s => s.codice === e.target.value)
                        if (documento) {
                            setSelectedDocument(documento)
                        }
                    }}>
                        {documents.map((document: DocumentModel) => {
                            return <option key={document.id}
                                           value={document.codice}>{t(`summingUpPage:DOCUMENT_SELECTION:${document.descrizione}`)}</option>
                        })}
                    </Select>
                    <Text fontSize={'x-small'}
                          fontWeight={'normal'}
                          textColor={'titleColor'}
                          ml={1}
                          as={'u'}
                          onClick={handleDocumentWarning}>{t('summingUpPage:TEXTS:DOCUMENT_WARNING')}</Text>
                </FormControl>

                <Divider mt={3} mb={1} ml={2}/>


                <Checkbox isInvalid={isPrivacyInvalid} mt={3} ml={2} size={'lg'} onChange={(e) => {
                    setPrivacyApproval(!privacyApproval)
                    setIsPrivacyInvalid(false)
                }}>
                    <Box pl={3}>
                        <Text fontSize={'x-small'} fontWeight={'bold'}
                              textColor={'titleColor'}
                              onClick={handleOpenPrivacy}>{t('summingUpPage:TEXTS:PRIVACY_POLICY:TITLE')}</Text>
                    </Box>
                </Checkbox>
                <Divider mt={3} mb={1} ml={2}/>
                <Text fontSize={'x-small'}
                      fontWeight={'normal'}
                      textColor={'titleColor'}
                      ml={2}
                      as={'u'}
                      onClick={handleOpenPrivacy}>{t('summingUpPage:TEXTS:PRIVACY_POLICY:SUBTITLE')}</Text>

                <Box h={10}/>
                <MyButton myType={'PRIMARY'} mb={2} onClick={handleAddDocument}><Text>{getButtonText()}</Text>
                </MyButton>
                <MenuLanguageComponent/>
                <Box h={5}/>
            </Box>
        </MyPage>
    );
};
