import React, {FC, useEffect} from 'react';
import {Box, Center, Image as CKImage, Skeleton, Spinner} from '@chakra-ui/react';

interface MyImageProps {
    src?: string;
    className?: string;
    style?: React.CSSProperties;
    skeletonHeight?: string;
    onLoadingComplete?: () => void;
}

export const MyImage: FC<MyImageProps> = (props) => {
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        setIsLoading(true);
        loadImg();
        // disable the loading in any case after 3 seconds
        const timeout = setTimeout(() => {
            if (props.onLoadingComplete) {
                props.onLoadingComplete()
            }
        }, 3000);
    }, [props.src]);

    const loadImg = async () => {
        if (props.src) {
            let img = new Image();
            img.src = props.src;
            await Promise.all([
                await img.onload,
                await img.decode()
            ])
            setIsLoading(false);
            setTimeout(() => {
                if (props.onLoadingComplete) {
                    props.onLoadingComplete()
                }
            }, 100);
        }
    }


    if (isLoading || !props.src) {
        return (
            <Box position={'relative'} h={'full'} style={props.style}>
                <Center position={'absolute'} w={'full'} h={'full'}>
                    <Spinner/>
                </Center>
                <Skeleton height={props.skeletonHeight ?? 'full'}/>
            </Box>
        )
    }

    return (
        <CKImage src={props.src} className={props.className} style={props.style} alt={'Immagine struttura'}/>
    );
};
