import {boolean, object, string} from 'yup';
import {simpleDataValidation} from 'utils/validation.utils';
import CodiceFiscale  from 'codice-fiscale-js';

/**
 * Logic:
 *
 * If personaFisica is true:
 * - nome, cognome, codiceFiscale, indirizzo are required
 * Otherwise:
 * - partitaIva, sdi, ragioneSociale are required
 */


export const billingValidationSchema = object({
    nome: string().when('personaFisica',
        {
            is: true,
            then: schema => schema.required('common:VALIDATION:NOME:REQUIRED')
                .trim()
                .min(2, 'common:VALIDATION:NOME:IS_TO_SHORT')
                .max(30, 'common:VALIDATION:NOME:IS_TO_LONG')
                .test('Controllo Validita', 'common:VALIDATION:NOT_VALID', simpleDataValidation)
        }
    ),
    cognome: string().when('personaFisica',
        {
            is: true,
            then: schema => schema.required('common:VALIDATION:COGNOME:REQUIRED')
                .trim()
                .min(2, 'common:VALIDATION:COGNOME:IS_TO_SHORT')
                .max(50, 'common:VALIDATION:COGNOME:IS_TO_LONG')
                .test('Controllo Validita', 'common:VALIDATION:NOT_VALID', simpleDataValidation),
        }
    ),
    codiceFiscale: string().when(['personaFisica', 'nazione'],
        {
            is: (personaFisica: boolean, nazione: string) => personaFisica && nazione == 'ITALIA',
            then: schema => schema.required('common:VALIDATION:CODICE_FISCALE:REQUIRED')
                .trim()
                .test('Controllo Codice Fiscale', 'common:VALIDATION:CODICE_FISCALE:NOT_VALID', (value) => {
                    if (value === undefined || value == '') return true

                    return value ? CodiceFiscale.check(value) : false
                }),
        }
    ),
    indirizzo: string().required('common:VALIDATION:INDIRIZZO:REQUIRED').trim(),
    citta: string().required('common:VALIDATION:CITTA:REQUIRED').trim(),
    provincia: string().required('common:VALIDATION:PROVINCIA:REQUIRED').trim(),
    cap: string().required('common:VALIDATION:CAP:REQUIRED').trim(),
    nazione: string().required('common:VALIDATION:NAZIONE:REQUIRED').trim(),
    partitaIva: string().when('personaFisica',
        {
            is: false,
            then: schema => schema.required('common:VALIDATION:PARTITA_IVA:REQUIRED')
                .trim()
        }
    ),
    sdi: string().trim(),
    ragioneSociale: string().when('personaFisica',
        {
            is: false,
            then: schema => schema.required('common:VALIDATION:RAGIONE_SOCIALE:REQUIRED')
                .trim()
        }
    ),
    personaFisica: boolean().required('common:VALIDATION:PERSONA_FISICA:REQUIRED')
});



