import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {AuthActions} from 'store/auth/auth.action';
import {checkinActions} from 'store/checkin/checkin.action';
import {SUPPORTED_LANGUAGES} from 'i18n/i18n.config';
import {SettingsActions} from 'store/settings/settings.action';
import {useLocation} from 'react-router-dom';
import MyPage from 'shared/components/defaultComponents/myPage/myPage.component';


export const AuthProvider:FC = ({children}) => {

    const dispatch = useAppDispatch()
    const {initialized,isAuth,isError} = useAppSelector(state => state.authReducer);
    const {prenotazione} = useAppSelector(state => state.checkinReducer);
    const {serverLanguage} = useAppSelector(state => state.settingsReducer);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();


    useEffect(() => {
        const lingua = prenotazione?.extras.lingua;
        if(lingua && serverLanguage){
            dispatch(SettingsActions.setLanguageSetting({language:lingua as SUPPORTED_LANGUAGES,overrideServer:false}));
        }
    }, [prenotazione?.extras.lingua]);


    useEffect(()=>{
        handleAuth()
    },[])


    useEffect(() => {
        if (isError) {
            logOut()
        }
    }, [isError])

    const logOut = async () => {
        await dispatch(AuthActions.logoutAction())
        setIsLoading(false);
    }

    const handleAuth = async () =>{
        const currentRoute = location.pathname.split('/')[1];
        //const chiaveAccessoApp = location.pathname.split('/')[2];
        if(currentRoute === 'login'){
            await logOut();
        }

        if (currentRoute === 'document-scanning-analysis') {
            await dispatch(AuthActions.authenticateWithToken())
        }else if(!isAuth && !initialized){
            await dispatch(AuthActions.authenticateWithToken())
        } else if (!prenotazione && !initialized){
            await dispatch(checkinActions.fetchBooking());
        }
        setIsLoading(false);
    }

    return (
        <>
            {isLoading || !initialized || isError?
                <MyPage header={{pageName:''}} isLoading={true} isError={isError} />
                :children}
        </>
    );
};

