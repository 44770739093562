import React, {FC} from 'react';
import {Box, Flex, Progress, Text} from '@chakra-ui/react';
import LogoImage from 'assets/logos/homehotel_b.png';
import {HEADER_WITH_STEPPER_HEIGHT} from 'shared/components/defaultComponents/myPage/myPage.component';

export interface HeaderWithStepperProps{
    stepNumber: number,
    totalSteps: number,
    pageTitle?:string,
    pageSubTitle?:string,
}

export const HeaderWithStepper:FC<HeaderWithStepperProps> = ({stepNumber,totalSteps,pageTitle,pageSubTitle}) => {

    const calculatePercentage = () =>{
        let value =  (100* (stepNumber + 1))/totalSteps
        if(value>0){
            return value
        }
        return 2
    }

    return (
        <Box position={'sticky'} h={HEADER_WITH_STEPPER_HEIGHT} w={'full'} px={1}  boxShadow='base' top={0} left={0} zIndex={1000} backgroundColor={'background'} display={{base:'block',lg:"none"}} >
            <Flex w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                <img src={LogoImage} width={120}/>
            </Flex>
            <Progress colorScheme='primary' size='md' value={calculatePercentage()} mb={ pageTitle || pageSubTitle ? 2 :undefined} />
            {pageTitle && <Text color={'primary.500'} >{`${pageTitle} ${(stepNumber+1)}/${totalSteps}`}</Text>}
            {pageSubTitle && <Text color={'subtitleColor'} >{pageSubTitle}</Text>}
        </Box>
    )
};

