import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enCommon from './common-translations/en.json';
import itCommon from './common-translations/it.json';

export enum SUPPORTED_LANGUAGES {
    EN = 'en',
    IT = 'it',
}

export const defaultLanguage = SUPPORTED_LANGUAGES.IT;
/**
 * initializer of the translate module
 */
export const i18Mfe = i18next
    .use(LanguageDetector)
    .createInstance({
        lng: defaultLanguage,
        fallbackLng: defaultLanguage,
        resources: {
            en: {
                common: enCommon,
            },
            it: {
                common: itCommon,
            },
        },
        interpolation: {
            escapeValue: false,
            format: (value, format, lng) => {
                if (format === 'uppercase') {
                    return value.toUpperCase();
                }
                if (format === 'lowercase') {
                    return value.toLowerCase();
                }
                if (format === 'capitalize') {
                    const firstChar = value.charAt(0);
                    return firstChar.toUpperCase() + value.slice(1).toLowerCase()
                }
                return value
            }
        },
        react: {
            transSupportBasicHtmlNodes: true,
            useSuspense:true
        },
    });

export const initAppTranslator = async () => {
    i18Mfe.init();
};

export const setUserSelectedLanguage = (language: string) => {
    console.log('setUserSelectedLanguage', language);
    i18Mfe.changeLanguage(language);
};
