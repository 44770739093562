import React, {FC, useEffect} from 'react';
import {useAppSelector} from 'store/store.config';
import {useToast} from '@chakra-ui/react';
import {ToastMessage} from 'store/uiManager/types';
import {useTranslation} from 'react-i18next';

export const ToastProvider: FC = ({children}) => {
    const {t} = useTranslation()
    const {toast} = useAppSelector(state => state.uiManagerReducer)
    const chakraToast = useToast()
    const TOAST_DURATION = 3000;

    useEffect(() => {
        if(toast?.description && !chakraToast.isActive(toast.description)) {
            showToast(toast)
        }
    }, [toast])

    const getExtraDescription = (description:string,extraDescription?:string) => {
        if(extraDescription === '' || !extraDescription || extraDescription === 'undefined') return description

        if  (description !== ''){
            return `${description} \n [${extraDescription}]`
        }

        return extraDescription
    }

    const getDescription = (toastMessage:ToastMessage) => {
        if  (toastMessage.isI18nKey){
            return getExtraDescription(t(toastMessage.description),toastMessage.extraDescription)
        }
        return getExtraDescription(toastMessage.description,toastMessage.extraDescription)
    }

    const getTitle = (toastMessage:ToastMessage) => {
        const addStatusCode = (title:string) => {
            if(toastMessage.statusCode){
                return `[${toastMessage.statusCode}] ${title}`
            }
            return title
        }

        if  (toastMessage.isI18nKey){
            return addStatusCode(t(toastMessage.title))
        }
        return addStatusCode(toastMessage.title)
    }

    const showToast = (toastMessage:ToastMessage) => {
        chakraToast({
            title: getTitle(toastMessage),
            description: getDescription(toastMessage),
            duration: toastMessage.duration ? toastMessage.duration : TOAST_DURATION, //
            status: toastMessage.status,
            isClosable: toastMessage.isClosable? toastMessage.isClosable : true,
            position: toastMessage.position? toastMessage.position : 'top',
            id: toastMessage.description
        })
    }


    return (
        <>
            {children}
        </>
    );
};
