import {createAction, createAsyncThunk} from '@reduxjs/toolkit';
import {AuthService} from 'service/auth.service';
import {LoginAuthenticateResponse, LoginParams} from 'store/auth/types';
import {CheckInService} from 'service/checkIn.service';
import {initAppTranslator} from 'i18n/i18n.config';
import {uiManagerActions} from 'store/uiManager/uiManager.action';
import { AxiosError } from 'axios';

initAppTranslator();

export const enum AUTH_ACTION {
    REFRESH_AUTH = 'REFRESH_AUTH',
    LOGIN = 'LOGIN',
    LOGIN_WITH_TOKEN = 'LOGIN_WITH_TOKEN',
    LOGOUT = 'LOGOUT',
}

const refreshAuthAction = createAction(AUTH_ACTION.REFRESH_AUTH, (payload) => {
    return {payload}
})

const loginAction = createAsyncThunk<LoginAuthenticateResponse,LoginParams>(AUTH_ACTION.LOGIN, async (params,thunkAPI) => {
    try {
        return await AuthService.loginMethod(params);
    } catch (e: any) {
        const error = e as AxiosError
        if (error.response) {
            console.log('Error during Axios login: ',error.response)
            thunkAPI.dispatch(uiManagerActions.showToast({
                title: 'common:ERRORS:GENERIC:TITLE',
                description: 'common:ERRORS:LOGIN_ERROR',
                extraDescription: error.response.data ? 'BE: '+ error.response.data as any : undefined,
                statusCode: error.response.status,
                duration: 3000,
                status: 'error',
                isI18nKey: true
            }));
        }else{
            console.log('Error during login: ', e);
            thunkAPI.dispatch(uiManagerActions.showToast({
                title: 'common:ERRORS:GENERIC:TITLE',
                description: 'common:ERRORS:LOGIN_ERROR',
                extraDescription: e.message? 'FE: '+ e.message: undefined,
                duration: 3000,
                status: 'error',
                isI18nKey: true
            }));
        }
        throw e
    }
});

const authenticateWithToken = createAsyncThunk(AUTH_ACTION.LOGIN_WITH_TOKEN, async (params, thunkAPI): Promise<LoginAuthenticateResponse> => {
    try {
        const token = AuthService.getAccessToken();
        let hasConfirmedBooking = false;
        let prenotazione;
        let isAuth = false;

        if (token) {
            const response = await CheckInService.fetchBookingRequest();
            prenotazione =  response.prenotazione;
            hasConfirmedBooking = response.hasConfirmedBooking;
            isAuth = response.isAuth;
        }
        return {
            prenotazione,
            isAuth,
            hasConfirmedBooking
        }

    }catch (e){
        console.log('Error during login with token: ', e);
        throw e;
    }

});

const logoutAction = createAsyncThunk(AUTH_ACTION.LOGOUT,async (arg, thunkAPI) => {
    AuthService.resetAccessToken();
    return {payload: {}}
},);


export const AuthActions = {
    refreshAuthAction,
    loginAction,
    authenticateWithToken,
    logoutAction
}
