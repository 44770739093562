import moment from 'moment';

export const fakePromise = <T>(response: T, isError = false): Promise<T> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if(isError){
                return reject()
            }
            return resolve(response)
        }, 500)
    })
}

export const timeFormatter = (time: string) => {
    return  time.slice(0,-3)
}

export type ImageEncodedBase64 = string | ArrayBuffer | null

export const convertBase64 = (file:File):Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result as string);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}

export const isValidDate = (date:string) => {
    return !isNaN(new Date(date).valueOf());
}

export const datePickerMapper = (datePickerFieldValue: string | undefined | null): moment.Moment | undefined=> {
    if(datePickerFieldValue !== null && datePickerFieldValue !== undefined){
        if( isValidDate(datePickerFieldValue) && datePickerFieldValue.length > 0){
            try {
                const finalDate = moment(datePickerFieldValue, 'YYYY-MM-DD')
                console.log("datePickerMapper", finalDate)
                return finalDate
            }catch (e){
                return undefined
            }
        }
    }
    return undefined
}


export const getValidUrl = (url :string) => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if(/^(:\/\/)/.test(newUrl)){
        return `http${newUrl}`;
    }
    if(!/^(f|ht)tps?:\/\//i.test(newUrl)){
        return `http://${newUrl}`;
    }

    return newUrl;
};


export const addBase64Header = (data:string | undefined) =>{
    return data ? `data:image/png;base64,${data}` : undefined
}

// Execute inside the index.tsx file
export const windowResizingHeight = () => {
    document.documentElement.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
    window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
}


export const getEnvToBoolean = (env: string | undefined) => {
    return env === 'true'
}
