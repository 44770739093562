import {createAsyncThunk} from '@reduxjs/toolkit';
import {AutocompleteService} from 'service/autocomplete.service';

export const enum AUTOCOMPLETE_ACTION {
    FETCH_STATES= 'FETCH_STATES',
    FETCH_CITIES_SEARCH= 'FETCH_CITIES_SEARCH',
    FETCH_DOCUMENTS= 'FETCH_DOCUMENTS',
    FETCH_STATE_TRANSLATION = 'FETCH_STATE_TRANSLATION'
}

const fetchStates = createAsyncThunk(AUTOCOMPLETE_ACTION.FETCH_STATES, async (arg, thunkAPI) => {
    try{
        return await AutocompleteService.autocompleteStates()
    }catch (e:any) {
        throw e
    }
});

const fetchCitiesSearch = createAsyncThunk(AUTOCOMPLETE_ACTION.FETCH_CITIES_SEARCH, async (city:string,thunkAPI)=> {
    try{
        return await AutocompleteService.autocompleteCities(city)
    }catch (e:any) {
        console.log('Error fetchCitiesSearch',e);
        return []
    }
});

const fetchStateTranslation = createAsyncThunk(AUTOCOMPLETE_ACTION.FETCH_STATE_TRANSLATION, async (stateCode:string,thunkAPI)=> {
    try{
        return await AutocompleteService.autocompleteStateTranslation(stateCode)
    }catch (e:any) {
        throw e
    }
});

const fetchDocuments = createAsyncThunk(AUTOCOMPLETE_ACTION.FETCH_DOCUMENTS, async (arg,thunkAPI) => {
    try{
        return await AutocompleteService.autocompleteDocument()
    }catch (e:any) {
        throw e
    }
});

export const autocompleteActions = {
    fetchStates,
    fetchCitiesSearch,
    fetchDocuments,
    fetchStateTranslation
}
