import React, {FC, useEffect, useState} from 'react';
import {Box, Input, InputGroup, InputProps, InputRightAddon, Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {IoChevronForward} from 'react-icons/io5';
import {UseFormRegisterReturn} from 'react-hook-form';
import {borderRadius} from "style/styleConstants";
import {appThemeColors} from "style/chakraTheme";

const defaultInputProps = {variant: 'flushed', colorScheme: 'textColor'}
const outlineInputProps = {
    variant: 'outline',
    colorScheme: 'textColor',
    border: '1px solid',
    borderColor: appThemeColors.borderColor,
    borderRadius: borderRadius
}


interface MyInputProps extends InputProps {
    myType: 'FLUSHED' | 'OUTLINE' | 'EDITABLE' | 'ARROW_LINK'
    setEditToDisabled?: boolean,
    customOnChange?: (value: string) => string,
    formReg?: UseFormRegisterReturn
}

const MyInput: FC<MyInputProps> = (props) => {
    const {myType, customOnChange, setEditToDisabled, formReg, ...rest} = props
    const [editValue, setEditValue] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (setEditToDisabled) {
            setEditValue(false)
        }
    }, [setEditToDisabled])

    const chooseType = () => {
        switch (props.myType) {
            case 'FLUSHED':
                return defaultInputProps;
            case 'OUTLINE':
                return outlineInputProps
            case 'EDITABLE':
                return outlineInputProps
            case 'ARROW_LINK':
                return outlineInputProps
        }

    }

    const withCustomOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = customOnChange ? customOnChange(e.target.value) : e.target.value;
    }

    const renderInput = () => {
        switch (props.myType) {
            case 'EDITABLE':
                return (
                    <InputGroup size="md">
                        <Input isDisabled={!editValue} {...chooseType()} {...rest} {...formReg}
                               onChange={withCustomOnChange}/>
                        <InputRightAddon children={<Text fontSize={'sm'}
                                                         onClick={() => setEditValue(!editValue)}>{editValue ? t('common:BUTTONS:APPLY') : t('common:BUTTONS:EDIT')}</Text>}/>
                    </InputGroup>
                )
            case 'ARROW_LINK':
                const {onClick, ...subRest} = rest
                return (
                    <InputGroup size="md" onClick={onClick}>
                        <Input isDisabled={true} textColor={'#00000060'}
                               _disabled={{textColor: '#00000060'}} {...chooseType()} {...subRest} {...formReg}
                               onChange={withCustomOnChange}/>
                        <InputRightAddon children={<IoChevronForward/>}/>
                    </InputGroup>
                )
            default :
                return (
                    <Input {...chooseType()} _placeholder={{color: 'borderColor'}} {...rest} {...formReg}
                           onChange={withCustomOnChange}/>
                )
        }
    }
    return (
        <Box>
            {renderInput()}
        </Box>
    )
};

export default MyInput;
