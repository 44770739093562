import {createReducer} from '@reduxjs/toolkit';
import {AutocompleteState} from 'store/autocomplete/types';
import {autocompleteActions} from 'store/autocomplete/autocomplete.action';

const initialState: AutocompleteState = {
    isLoading: false,
    isError: false,
    states: [],
    cities: [],
    documents: []
};

export const autocompleteReducer = createReducer(initialState,builder => {
    builder.addCase(autocompleteActions.fetchStates.pending, (state, action) => {
        return{
            ...state,
            isLoading: true,
            isError: false,
        }
    });
    builder.addCase(autocompleteActions.fetchStates.fulfilled, (state, action) => {
        return{
            ...state,
            isLoading: false,
            isError: false,
            states: [...action.payload],
        }
    });
    builder.addCase(autocompleteActions.fetchStates.rejected, (state, action) => {
        return{
            ...state,
            isLoading: false,
            isError: true,
        }
    });
    // Fetch city search
    builder.addCase(autocompleteActions.fetchCitiesSearch.pending, (state, action) => {
        return{
            ...state,
            isLoading: true,
            isError: false,
        }
    });
    builder.addCase(autocompleteActions.fetchCitiesSearch.fulfilled, (state, action) => {
        return{
            ...state,
            isLoading: false,
            isError: false,
            cities: [...action.payload],
        }
    });
    builder.addCase(autocompleteActions.fetchCitiesSearch.rejected, (state, action) => {
        return{
            ...state,
            isLoading: false,
            isError: true,
        }
    });
    // Fetch documents
    builder.addCase(autocompleteActions.fetchDocuments.pending, (state, action) => {
        return{
            ...state,
            isLoading: true,
            isError: false,
        }
    });
    builder.addCase(autocompleteActions.fetchDocuments.fulfilled, (state, action) => {
        return{
            ...state,
            isLoading: false,
            isError: false,
            documents: [...action.payload],
        }
    });
    builder.addCase(autocompleteActions.fetchDocuments.rejected, (state, action) => {
        return{
            ...state,
            isLoading: false,
            isError: true,
        }
    });
});
