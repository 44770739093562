
//Data marked as OUT FORM ar data that are not inserted from the user but calculated from the data inserted

export interface UtenteBaseModel{
    id?: number;
    uid: string;
    nome: string;
    cognome: string;
    dataDiNascita: string;

    statoNascita: string;
    statoNascitaPortale: string; //OUT FORM

    nazionalita: string;
    nazionalitaPortale: string; //OUT FORM
    //SOLO ITALIANI
    luogoDiNascita?: string;
    luogoDiNascitaPortale?: string; //OUT FORM

    comuneDiResidenza?: string;
    comuneDiResidenzaPortale?: string; //OUT FORM
    //END SOLO ITALIANI

    sesso: Genders;
    tipoAlloggiato: number, // 16,18,20

    nazioneDiResidenza?: string;
    nazioneDiResidenzaPortale?: string; //OUT FORM

}

export interface DocumentoModel{
    numeroDocumento: string;

    tipoDocumento: string;
    tipoDocumentoPortale: string; //OUT FORM

    scadenzaDocumento: string;
    dataRilascioDocumento?: string;

    luogoRilascioDocumento?: string;
    luogoRilascioDocumentoPortale?: string; //OUT FORM

    comuneRilascioDocumento?: string; //used only frontend
    comuneRilascioDocumentoPortale?: string; //OUT FORM, used only frontend

    //IMG
    fotoDocumentoFronte: string; //OUT FORM
    fotoDocumentoRetro: string; //OUT FORM
}

export interface UtenteConDocumentoModel extends UtenteBaseModel,DocumentoModel{
    //IMG
    fotoSelfie: string; //OUT FORM
}

export enum Genders {
    MALE = 1,
    FEMALE = 2
}

export const enum TIPO_ALLOGGIATO{
    CAPOGRUPPO_SENZA_PARTECIPANTI = 16,
    CAPOGRUPPO_CON_PARTECIPANTI = 18,
    UTENTE_BASE = 20
}
