import React from 'react';
import {Redirect, RouteProps} from 'react-router-dom';
import {useAppSelector} from 'store/store.config';
import {RoutesPaths} from 'navigation/routes';
import PrivateRoute from 'navigation/privateRoute';

const HomePrivateRoutes: React.FC<RouteProps> = (props) => {
    const {hasConfirmedBooking} = useAppSelector(state => state.authReducer)

    return hasConfirmedBooking ?
        (<PrivateRoute path={props.path} exact={props.exact} component={props.component}/>)
        :
        (<Redirect to={RoutesPaths.SUMMINGUP.toString()}/>);
};
export default HomePrivateRoutes;
