import React, {useEffect, useState} from 'react';
import {Box, Center, Divider, Flex, HStack, Stack, Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import enTexts from 'screens/summingUp/i18n/en.json';
import itTexts from 'screens/summingUp/i18n/it.json';
import {loadTranslations} from 'i18n/i18n.service';
import {RoutesPaths} from 'navigation/routes';
import {useHistory} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import MyPage from 'shared/components/defaultComponents/myPage/myPage.component';
import {VeriffService} from 'service/veriff.service';
import MyButton from 'shared/components/defaultComponents/myButton/myButton.component';
import ScanDocumentImg from "assets/general/scan_document.svg";
import {borderRadius} from "style/styleConstants";
import {MyImage} from "shared/components/defaultComponents/myImage/myImage.component";
import {VeriffOnSessionResponse} from "models/veriff.model";
//@ts-ignore
import {Veriff} from "@veriff/js-sdk";

export const DocumentScanning = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const i18nTexts = {
        'en': enTexts,
        'it': itTexts,
    };
    loadTranslations('summingUpPage', i18nTexts)
    const {utils} = useAppSelector(state => state.checkinReducer);
    const [serviceUnavailable, setServiceUnavailable] = useState(false);

    useEffect(() => {
        if (!utils?.documentType) {
            history.push(RoutesPaths.DOCUMENT_SELECTION.toString());
        }
    }, []);


    useEffect(() => {
        try {
            const veriff = Veriff({
                host: process.env.REACT_APP_VERIFF_HOST,
                apiKey: process.env.REACT_APP_VERIFF_API_KEY,
                parentId: 'veriff-root',
                onSession: async (_err: any, response: VeriffOnSessionResponse) => {
                    console.log(response, "Veriff response")
                    VeriffService.setVeriffSessionId(response.verification.id);
                    const sessionCreated = await VeriffService.setVeriffSessionIdRequest(response.verification.id);
                    if (!sessionCreated) {
                        setServiceUnavailable(true);
                        return;
                    }
                    window.location.href = response.verification.url;
                }
            });
            veriff.setParams({
                person: {
                    givenName: ' ',
                    lastName: ' '
                },
                vendorData: ' '
            });
            veriff.mount();
        } catch (e) {
            setServiceUnavailable(true);
        }

    }, []);

    const handleNextPage = () => {
        history.push(RoutesPaths.SUMMINGUP_ADDBOOKER.toString())
    }

    return (
        <MyPage header={{pageName: ''}}
                isLoading={isLoading}
                mainContainerProps={{textAlign: 'start', align: 'start'}}>
            <Stack paddingX={3} w={"full"} justify={'center'} align={'start'}>
                <Flex w={"full"} align={"start"}>
                    <Text fontSize={'x-large'} color={'titleColor'}
                          align={"start"}
                          position={'relative'}
                          left={0}
                          zIndex={20}
                          fontWeight={'bold'}>{t('summingUpPage:TEXTS.DOCUMENT_SCANNING_TITLE')}</Text>
                </Flex>
                <HStack>
                    <MyImage src={ScanDocumentImg} style={{height: 100}}/>
                    <Text fontSize={'md'}
                          color={'subtitleColor'}>{t('summingUpPage:TEXTS.DOCUMENT_SCANNING_SUBTITLE')}</Text>
                </HStack>
                {serviceUnavailable &&
                    <Center>
                        <Box border={'1px solid'} borderColor={'borderColor'}
                             borderRadius={borderRadius} p={3}>
                            <Text fontSize={'sm'} color={'subtitleColor'}>
                                {t('summingUpPage:ERRORS:SCAN_SERVICE_UNAVAILABLE')}
                            </Text>
                        </Box>
                    </Center>
                }
            </Stack>
            <Box h={35}/>
            <Center w={'full'} px={3}>
                <div id='veriff-root'></div>
            </Center>
            <HStack w={'full'} py={5} px={3}>
                <Divider/>
                <Text textColor={'subtitleColor'}>o</Text>
                <Divider/>
            </HStack>
            <Box w={'full'} px={3}>
                <MyButton myType={'PRIMARY'}
                          onClick={() => handleNextPage()}><Text>{t('summingUpPage:BUTTONS:PROCEDE_WITH_DATA_ENTRY')}</Text>
                </MyButton>
            </Box>
        </MyPage>
    );
};
