import React, {FC} from 'react';
import {Control, Controller} from 'react-hook-form';
import {MyDateInput} from "shared/components/defaultComponents/myDateInput/myDateInput.component";

export interface MyDatePickerProps {
    control: Control<any>;
    name: string;
    placeholder: string;
    allowClear?: boolean;
    isDisabled?: boolean;
}

export const MyDatePicker: FC<MyDatePickerProps> = ({name, control, allowClear, placeholder, isDisabled}) => {

    return (
        <Controller
            control={control}
            name={name}
            render={({field}) => {

                function formatDateString(input: string): string {
                    try {
                        // Use regular expressions to match either 'yyyy-mm-dd' or 'dd/mm/yyyy' formats
                        const yyyy_mm_dd_regex = /^\d{4}-\d{2}-\d{2}$/;
                        const dd_mm_yyyy_regex = /^\d{2}\/\d{2}\/\d{4}$/;

                        if (yyyy_mm_dd_regex.test(input)) {
                            // If it matches 'yyyy-mm-dd', convert to 'dd/mm/yyyy'
                            const [year, month, day] = input.split('-');
                            return `${day}/${month}/${year}`;
                        } else if (dd_mm_yyyy_regex.test(input)) {
                            // If it matches 'dd/mm/yyyy', return it as is
                            return input;
                        } else {
                            // If it doesn't match either format, return null or handle it as needed.
                            return '';
                        }
                    } catch (e) {
                        console.log('Error parsing date string', e);
                        return ''
                    }
                }

                return (
                    <MyDateInput date={formatDateString(field.value)} setDate={field.onChange} isDisabled={isDisabled}/>
                )
            }}
        />
    );
};
