import React, {FC} from 'react';
import {FormControl, FormErrorMessage, FormLabel} from '@chakra-ui/react';
import MyInput from 'shared/components/defaultComponents/myInput/myInput.component';
import {FieldError} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

export interface MyFormControllerProps {
    label?: string;
    placeHolder?: string;
    noPlaceholder?: boolean;
    customOnChange?: (value:string) => string,
    errorField: FieldError | undefined;
    isDisabled?: boolean;
    register: any; // should be passed as register('fieldName')
}

export const MyFormController: FC<MyFormControllerProps> = (props) => {
    const {register, errorField, label} = props;
    const {t} = useTranslation();

    const getPlaceholder = () => {
        if (props.noPlaceholder) {
            return undefined;
        }
        if (props.placeHolder) {
            return props.placeHolder;
        }
        return props.label || '';
    }

    return (
        <FormControl isInvalid={!!errorField}>
            {label && <FormLabel color={'titleColor'}>{label}</FormLabel>}
            <MyInput formReg={register} myType={'OUTLINE'}
                     customOnChange={props?.customOnChange}
                     isDisabled={props.isDisabled}
                     placeholder={getPlaceholder()}/>
            <FormErrorMessage>{t(`${errorField?.message}`)}</FormErrorMessage>
        </FormControl>
    );
};
