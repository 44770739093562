import {Genders, UtenteConDocumentoModel} from 'models/user.model';
import {FieldErrors} from 'react-hook-form/dist/types/errors';

export const utenteConDocumentoFormValidationOrder: Array<keyof FieldErrors<UtenteConDocumentoModel> | undefined> = ['nome','cognome','dataDiNascita','statoNascita','nazionalita','luogoDiNascita','comuneDiResidenza','sesso','nazioneDiResidenza','numeroDocumento','tipoDocumento','scadenzaDocumento','dataRilascioDocumento','luogoRilascioDocumento',undefined]

export const UtenteConDocumentoFormData= (partialUser?:Partial<UtenteConDocumentoModel>):UtenteConDocumentoModel => {

    return {
        uid: '',
        nome: '',
        cognome: '',
        dataDiNascita: '',

        statoNascita: '',
        statoNascitaPortale: '',

        nazionalita: '',
        nazionalitaPortale: '',

        sesso: Genders.MALE,
        tipoAlloggiato: 16,

        nazioneDiResidenza: '',
        nazioneDiResidenzaPortale: '',

        //Documento
        numeroDocumento: '',
        scadenzaDocumento: '',
        dataRilascioDocumento: '',

        tipoDocumento: '',
        tipoDocumentoPortale: '',

        fotoDocumentoFronte: '',
        fotoDocumentoRetro: '',

        luogoRilascioDocumento: '',
        luogoRilascioDocumentoPortale: '',
        //SOLO ITALIANI
        comuneRilascioDocumento: '',
        comuneRilascioDocumentoPortale: '',

        luogoDiNascita: '',
        luogoDiNascitaPortale: '',

        comuneDiResidenza: '',
        comuneDiResidenzaPortale: '',

        // EXTRA
        fotoSelfie: '',
        ...partialUser
    }
}
