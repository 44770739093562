import React, {FC} from 'react';
import {MySquareIcon} from "../MySquareIcon/mySquareIcon.component";
import {IoPencil, IoPersonCircle} from "react-icons/io5";
import {HStack, Spacer, Text, VStack} from "@chakra-ui/react";
import {RoutesPaths} from "../../../navigation/routes";
import {appThemeColors} from "../../../style/chakraTheme";
import {UtenteBaseModel, UtenteConDocumentoModel} from "../../../models/user.model";
import {useHistory} from "react-router-dom";

interface UserListItemProps {
    user: UtenteConDocumentoModel | UtenteBaseModel
    isBooker?: boolean
    title: string
    isEditable?: boolean
}

export const UserListItem: FC<UserListItemProps> = (props) => {
    const history = useHistory();

    const handleEdit = () => {
        if (props.isBooker) {
            history.push(RoutesPaths.SUMMINGUP_ADDBOOKER.toString() + `/edit`)
        }else {
            history.push(RoutesPaths.SUMMINGUP_ADDUSER.toString() + `/${props.user.uid}`)
        }
    }

    return (
        <HStack w={"full"} justifyContent={'start'} align={'center'} ml={1}>
            <MySquareIcon icon={IoPersonCircle}/>
            <VStack align={'start'} p={0}>
                <Text fontSize={'md'} fontWeight={'normal'} align={'center'} p={0}
                      color={'titleColor'}>{props.title}</Text>
                <Text fontSize={'md'} my={1} fontWeight={'light'} align={'center'} p={0}
                      color={'titleColor'}>{`${props.user?.nome} ${props.user?.cognome}`}</Text>
            </VStack>
            {props.isEditable && (
                <>
                    <Spacer/>
                    <MySquareIcon onClick={handleEdit}
                                  icon={IoPencil}
                                  size={25} color={appThemeColors.iconColor["500"]}
                                  backgroundColor={appThemeColors.iconColor["100"]}/>
                </>
            )}
        </HStack>
    )
};
