import {VarcoModel} from 'models/varco.model';
import {AxiosError, AxiosResponse} from 'axios';
import {appAxios} from 'service/axios.config';
import {ProceduraModel} from 'models/procedura.model';
import {LinkModel} from 'models/link.model';
import {GeolocationModel, GeolocationWithPermissionModel} from 'models/geolocation.model';

const fetchVarchi = async (): Promise<VarcoModel[]> => {
    const response: AxiosResponse<VarcoModel[]> = await appAxios.get(`/webappguest/getVarchi`)
    console.log('Request [fetchVarchi] ', response.data)
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error fetching varchi')
}

const fetchProcedure = async (): Promise<ProceduraModel[]> => {
    const response: AxiosResponse<ProceduraModel[]> = await appAxios.get(`/webappguest/getProcedure`)
    console.log('Request [fetchProcedure] ', response.data)
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error fetching procedure')
}

const fetchLink = async (): Promise<LinkModel[]> => {
    const response: AxiosResponse<LinkModel[]> = await appAxios.get(`/webappguest/getLinkUtili`)
    console.log('Request [fetchLink] ', response.data)
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error fetching link')
}


const openVarco = async (id: number, geolocation: GeolocationModel, forzatura: boolean): Promise<boolean> => {
    try {
        const response: AxiosResponse = await appAxios.post(`/webappguest/openVarco/${id}`, {
            ...geolocation,
            forzatura: forzatura
        })
        console.log('Request [openVarco] ', response.data)
        if (response && response.status === 200) {
            return true
        }
    } catch (e: any) {
        const error = e as AxiosError
        if (error.response?.status === 409) {
            return false
        }
    }


    throw new Error('Error opening varco')
}

const getPinVarco = async (id: number): Promise<string> => {
    const response: AxiosResponse<{ pin: string }> = await appAxios.get(`/webappguest/getPin/${id}`)
    console.log('Request [openVarco] ', response.data)
    if (response && response.data) {
        return response.data.pin
    }
    throw new Error('Error opening varco')
}

const accettazionePosizione = async (geolocation: GeolocationWithPermissionModel): Promise<void> => {
    const response: AxiosResponse = await appAxios.post(`/webappguest/accettazionePosizione`, geolocation)
    console.log('Request [accettazionePosizione] ', response.data)
    if (response && response.status === 200) {
        return
    }
    console.log('Error accettazionePosizione geolocation')
}

const notificaApertura = async (): Promise<void> => {
    const response: AxiosResponse = await appAxios.post(`/webappguest/richiestaProceduraEmergenza`)
    console.log('Request [notificaApertura] ', response)
    if (response && response.status === 200) {
        return
    }
    throw new Error('Error notificaApertura')
}

export const PostPrenotazioneService = {
    fetchVarchi,
    openVarco,
    fetchProcedure,
    fetchLink,
    getPinVarco,
    accettazionePosizione,
    notificaApertura
}
