export interface VeriffResponse {
    data: Data
    time: string
    status: string
    version: string
    attemptId: string
    eventType: string
    sessionId: string
    vendorData: string
    acceptanceTime: string
}
export interface Data {
    verification: Verification
}

export interface Verification {
    person: Person
    decision: string
    document: Document
    insights: Insight[]
    decisionScore: number
}

export interface Person {
    gender: Gender
    address: Address
    idNumber: IdNumber
    lastName: LastName
    firstName: FirstName
    dateOfBirth: DateOfBirth
    nationality: Nationality
}

export interface Gender {
    value: string
    sources: string[]
    confidenceCategory: string
}

export interface Address {
    value: string
    sources: string[]
    confidenceCategory: string
}

export interface IdNumber {
    value: string
    sources: string[]
    confidenceCategory: string
}

export interface LastName {
    value: string
    sources: string[]
    confidenceCategory: string
}

export interface FirstName {
    value: string
    sources: string[]
    confidenceCategory: string
}

export interface DateOfBirth {
    value: string
    sources: string[]
    confidenceCategory: string
}

export interface Nationality {
    value: string
    sources: string[]
    confidenceCategory: string
}

export interface Document {
    type: Type
    number: Number
    country: Country
    validFrom: ValidFrom
    validUntil: ValidUntil
}

export interface Type {
    value: string
}

export interface Number {
    value: string
    sources: string[]
    confidenceCategory: string
}

export interface Country {
    value: string
}

export interface ValidFrom {
    value: string
    sources: string[]
    confidenceCategory: string
}

export interface ValidUntil {
    value: string
    sources: string[]
    confidenceCategory: string
}

export interface Insight {
    label: string
    result: string
    category: string
}


export type VeriffGender = 'M' | 'F' | null;

export interface ParsedAddress {
    city?: null;
    unit?: null;
    state?: string;
    street?: string;
    country?: string; // ISO-2 Document country
    postcode?: string;
    houseNumber?: string;
}



export enum MESSAGES {
    STARTED = "STARTED",
    FINISHED = "FINISHED",
    CANCELED = "CANCELED",
    RELOAD_REQUEST = "RELOAD"
}

export interface VeriffOnSessionResponse {
    status: string;
    verification: {
        host: string;
        id: string;
        sessionToken: string;
        url: string;
        vendorData: string;
    };
}

export enum veriffDocumentType {
    PASSPORT = 'passport',
    ID_CARD = 'id_card',
    DRIVERS_LICENSE = 'drivers_license'
}


export interface GetVeriffResponse {
    veriffResponse: VeriffResponse,
}
