import React from 'react';
import {Button, ButtonProps} from '@chakra-ui/react';
import {borderRadius} from "style/styleConstants";

const defaultButtonProps = {w:'full', colorScheme:'primary', color:'lightButtonColor'}
const outlineButtonProps = {w:'full',border:'solid 1px',borderColor:'borderColor',fontSize:'sm', color:'darkButtonColor', backgroundColor:'background'  }
const linkButtonProps = { w:'full', fontSize:'sm', color:'darkButtonColor', backgroundColor:'background', textDecoration:'underline' }
interface MyButtonProps extends ButtonProps {
    myType: 'PRIMARY' | 'OUTLINE' | 'LINK'
}

const MyButton:React.FC<MyButtonProps> = (props ) => {
    const {myType, ...rest} = props

    const chooseType = () => {
        switch (props.myType) {
            case 'PRIMARY': return defaultButtonProps
            case 'OUTLINE': return outlineButtonProps
            case 'LINK' : return linkButtonProps
        }

    }

    return (
    <Button {...chooseType()} borderRadius={borderRadius} {...rest} >{props.children}</Button>
    )
};

export default MyButton
