import React, {FC} from 'react';
import {Box, Divider, GridItem, Text} from "@chakra-ui/react";
import MyButton from "../../defaultComponents/myButton/myButton.component";
import {MenuLanguageComponent} from "../../../../i18n/component/menuLanguage.component";
import {useTranslation} from "react-i18next";
import {UtenteBaseModel, UtenteConDocumentoModel} from "../../../../models/user.model";
import {UserListItem} from "../../userListItem/userListItem.component";

export interface TableFinalSummingUpComponentProps{
    utenteCapoGruppo?: UtenteConDocumentoModel,
    utenteCheckInList?: UtenteBaseModel[],
    handleContinue?: ()=>void,
    viewMode?:boolean,
    isLoading?:boolean
}

export const TableFinalSummingUpComponent:FC<TableFinalSummingUpComponentProps> = ({utenteCapoGruppo,utenteCheckInList,handleContinue,viewMode,isLoading}) => {
    const {t} = useTranslation();

    return (
        <>
            {/* Divider Text */}
            <GridItem rowSpan={1} colSpan={4}>
                <Text fontWeight={'medium'} fontSize={'lg'} my={3}
                      color={'titleColor'}>{t('summingUpPage:TEXTS:GUESTS')}</Text>
            </GridItem>

            {/* Line 1 capogruppo*/}
            {utenteCapoGruppo &&
                <GridItem key={utenteCapoGruppo.uid} rowSpan={1} colSpan={4}>
                    <UserListItem title={t('summingUpPage:TEXTS:BOOKER')} isBooker={true} isEditable={!viewMode}
                                  user={utenteCapoGruppo}/>
                </GridItem>
            }
            {/* Divider */}
            <GridItem rowSpan={1} colSpan={4}>
                <Divider/>
            </GridItem>

            {/* Line 2*/}
            {utenteCheckInList && utenteCheckInList.map((utente, index) => {
                return (
                    <GridItem key={`${utente.uid}/${index}`} rowSpan={1} colSpan={4}>
                        <UserListItem title={t('summingUpPage:TEXTS:GUEST') + ` ${index + 1}`}
                                      isEditable={!viewMode} user={utente}/>
                    </GridItem>
                )
            })}


            {/* Next Button*/}
            {handleContinue && !viewMode  ?
                <GridItem rowSpan={2} colSpan={4}>
                    <Box w={'full'} h={"full"} position={'relative'}>
                        <MyButton myType={'PRIMARY'} isLoading={isLoading} my={6}
                                                onClick={handleContinue}>{t('summingUpPage:BUTTONS:CONFIRM_BOOKING')}</MyButton>
                        <MenuLanguageComponent/>
                    </Box>
                </GridItem>
                :
                // Bottom spacing for navbar
                <GridItem rowSpan={2} colSpan={4}>
                    <Box h={50}/>
                </GridItem>
            }
        </>
    );
};
