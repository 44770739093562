import {RouteProps} from 'react-router-dom';
import React from 'react';
import {SuccessPage} from 'screens/successPage/successPage.component';
import SummingUpAddBooker from 'screens/summingUp/screens/summingUpAddBooker/summingUpAddBooker.component'
import SummingUp from 'screens/summingUp/summingUp.component'
import {DocumentScanning} from 'screens/summingUp/screens/documentScanning/documentScanning.component';
import {DocumentSelection} from 'screens/summingUp/screens/documentSelection.component.tsx/documentSelection.component';
import NotFoundPage from 'screens/notFound/notFound.component';
import Login from 'screens/login/login.component'
import {SummingUpBilling} from 'screens/summingUp/screens/summingUpBilling/summingUpBilling.component';
import {
    DocumentScanningAnalysis
} from "screens/summingUp/screens/documentScanningAnalysis/documentScanningAnalysis.component";

const TestComponent = React.lazy(() => import('screens/test/test.component'));
const SummingUpAddUser = React.lazy(() => import('screens/summingUp/screens/summingUpAddUser/summingUpAddUser.component'));
const FinalSummingUp = React.lazy(() => import('screens/summingUp/screens/finalSummingUp/finalSummingUp.component'));
const TabPage = React.lazy(() => import('screens/tabPage/tabPage.component'));

type Routes = | 'TAB'
    | 'LOGIN'
    | 'TEST'
    | 'SUMMINGUP'
    | 'DOCUMENT_SELECTION'
    | 'DOCUMENT_SCANNING'
    | 'DOCUMENT_SCANNING_ANALYSIS'
    | 'SUMMINGUP_ADDBOOKER'
    | 'SUMMINGUP_BILLING'
    | 'SUMMINGUP_ADDUSER'
    | 'FINAL_SUMMINGUP'
    | 'SUCCESS'
    | 'NOT_FOUND';

export const RoutesPaths: Record<Routes, String> = {
    TAB: '/',
    LOGIN: '/login',
    SUMMINGUP: '/summing-up',
    DOCUMENT_SELECTION: '/document-selection',
    DOCUMENT_SCANNING: '/document-scanning',
    DOCUMENT_SCANNING_ANALYSIS: '/document-scanning-analysis',
    SUMMINGUP_ADDBOOKER: '/summing-up/add-booker',
    SUMMINGUP_BILLING: '/summing-up/billing',
    SUMMINGUP_ADDUSER: '/summing-up/add-user',
    FINAL_SUMMINGUP: '/final-summing-up',
    SUCCESS: '/success',
    TEST: '/test',
    NOT_FOUND: ''
}

export const AppRoutes: Record<Routes, RouteProps> = {
    TAB: {
        path: RoutesPaths.TAB.toString(),
        component: TabPage,
        exact: true
    },
    LOGIN: {
        path: RoutesPaths.LOGIN.toString()+'/:id?',
        component: Login,
    },
    SUMMINGUP: { //RIEPILOGO
        path: RoutesPaths.SUMMINGUP.toString(),
        component: SummingUp,
        exact: true
    },
    DOCUMENT_SELECTION: { //RIEPILOGO
        path: RoutesPaths.DOCUMENT_SELECTION.toString(),
        component: DocumentSelection,
        exact: true
    },
    DOCUMENT_SCANNING: { //RIEPILOGO
        path: RoutesPaths.DOCUMENT_SCANNING.toString(),
        component: DocumentScanning,
        exact: true
    },
    DOCUMENT_SCANNING_ANALYSIS: { //RIEPILOGO
        path: RoutesPaths.DOCUMENT_SCANNING_ANALYSIS.toString(),
        component: DocumentScanningAnalysis,
        exact: true
    },
    SUMMINGUP_ADDBOOKER: { //RIEPILOGO -> aggiunta utente principale dell ordine
        path: RoutesPaths.SUMMINGUP_ADDBOOKER.toString()+'/:edit?',
        component: SummingUpAddBooker,
        exact: true
    },
    SUMMINGUP_BILLING:{
        path: RoutesPaths.SUMMINGUP_BILLING.toString(),
        component: SummingUpBilling,
        exact: true
    },
    SUMMINGUP_ADDUSER: { //RIEPILOGO -> aggiunta utente
        path: RoutesPaths.SUMMINGUP_ADDUSER.toString()+'/:id?',
        component: SummingUpAddUser,
    },
    FINAL_SUMMINGUP:{
        path: RoutesPaths.FINAL_SUMMINGUP.toString(),
        component:FinalSummingUp,
        exact:true
    },
    SUCCESS: {
        path: RoutesPaths.SUCCESS.toString(),
        component: SuccessPage,
        exact: true
    },
    TEST: {
        path: RoutesPaths.TEST.toString(),
        component: TestComponent
    },
    NOT_FOUND:{
        component: NotFoundPage
    }
}

export interface AppRoutesType {
    route: RouteProps
}

export interface AddUserParams {
    id:string
}

export interface EditBookerParams {
    edit:string
}

export interface LoginParams {
    id:string
}
