import React, {FC, useEffect, useState} from 'react';
import {Input} from "@chakra-ui/react";
import {appThemeColors} from "style/chakraTheme";
import {borderRadius} from "style/styleConstants";

const outlineInputProps = {
    variant: 'outline',
    colorScheme: 'textColor',
    border: '1px solid',
    borderColor: appThemeColors.borderColor,
    borderRadius: borderRadius
}

export interface MyDateInputProps {
    date: string
    setDate: (value: string) => void
    isDisabled?: boolean;
}

export const MyDateInput: FC<MyDateInputProps> = (props) => {
    useEffect(() => {
        setDate(props.date)
    }, [props.date]);

    const [date, setDate] = useState<string>('');

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 10) {
            return;
        }

        let newValue = e.target.value.replace(/\//g, ''); // Remove any existing '/' characters

        const formattedValue = formatInputValue(newValue);
        setDate(formattedValue);
    };

    const formatInputValue = (value: string): string => {
        // Remove all non-digit characters
        value = value.replace(/\D/g, '');

        // Insert '/' after every 2 characters
        if (value.length >= 3) {
            value = value.slice(0, 2) + '/' + value.slice(2);
        }
        if (value.length >= 6) {
            value = value.slice(0, 5) + '/' + value.slice(5);
        }

        return value;
    };

    return (
        <Input
            type={'tel'}
            {...outlineInputProps}
            _placeholder={{color: 'borderColor'}}
            value={date}
            onChange={handleDateChange}
            placeholder="dd/mm/yyyy"
            isDisabled={props.isDisabled}
            onBlur={() => {
                try {
                    if (date.length !== 10) {
                        props.setDate('')
                        setDate('')
                        return;
                    }
                    const dateSplit = date.split('/');
                    const dateBackend = '' + dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0];
                    if (dateSplit[0] && dateSplit[1] && dateSplit[2]) {
                        props.setDate(dateBackend)
                        console.log('OnBlur date to backend', dateBackend)
                    } else {
                        throw new Error('Date not valid')
                    }
                } catch (e) {
                    props.setDate('')
                    setDate('')
                    console.log('OnBlur date to backend error', e)
                }
            }}
        />
    );
}
