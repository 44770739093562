import React, {useEffect, useState} from 'react';
import {Box, FormControl, FormHelperText, Input, Text} from '@chakra-ui/react';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {AuthActions} from 'store/auth/auth.action';
import {useHistory, useParams} from 'react-router-dom';
import {LoginParams} from 'navigation/routes';
import LogoImage from 'assets/logos/homehotel.png';
import MyButton from 'shared/components/defaultComponents/myButton/myButton.component';
import {Trans, useTranslation} from 'react-i18next';
import {MenuLanguageComponent} from 'i18n/component/menuLanguage.component';
import itTexts from './i18n/it.json';
import enTexts from './i18n/en.json';
import {loadTranslations} from 'i18n/i18n.service';
import {SettingsActions} from 'store/settings/settings.action';
import versionObject from 'assets/version.json';
import MyPage, {VSpaceEvenlyProps} from 'shared/components/defaultComponents/myPage/myPage.component';
import {borderRadius} from 'style/styleConstants';

const Login = () => {
    const {t} = useTranslation();
    const i18nTexts = {
        'en': enTexts,
        'it': itTexts,
    };
    loadTranslations('loginPage', i18nTexts)

    const isLoading = useAppSelector(state => state.authReducer.isLoading);
    const isError = useAppSelector(state => state.authReducer.isError);
    const [supportPhoneNumber, setSupportPhoneNumber] = useState('');
    const [isLoginFormVisible, setIsLoginFormVisible] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);

    const history = useHistory()
    const dispatch = useAppDispatch();
    const {id} = useParams<LoginParams>();

    const [userCode, setUserCode] = useState('');

    useEffect(() => {
        console.log('Id: ', id);
        (async () => {
            if (id) {
                setIsPageLoading(true)
                setUserCode(id)
                await handleLogin(id)
                setIsPageLoading(false)
            }
        })()

        handleSupport()
    }, []);

    const handleLogin = async (id: string) => {
        await dispatch(AuthActions.loginAction({chiaveAccessoApp: id.trim()}));
    }

    const handleSupport = async () => {
        dispatch(SettingsActions.getSupportNumber()).then((response) => {
            const phone = response.payload as string
            if (phone) {
                setSupportPhoneNumber(phone);
            }
        });
    }

    return (
        <MyPage mainContainerProps={{...VSpaceEvenlyProps, paddingX: 3}} isLoading={isPageLoading} isError={isError}>
            <img src={LogoImage} width={350}/>
            {isLoginFormVisible ? (
                <>
                    <FormControl>
                        <Input value={userCode} backgroundColor={'background'}
                               borderRadius={borderRadius}
                               onChange={(text) => setUserCode(text.target.value)}
                               placeholder={t('loginPage:INPUT_FIELDS.BOOKING_CODE')}/>
                        <FormHelperText>{t('loginPage:INPUT_FIELDS.BOOKING_CODE_SUBTITLE')}</FormHelperText>
                    </FormControl>

                    <MyButton myType={'PRIMARY'} isLoading={isLoading}
                              onClick={() => handleLogin(userCode)}>{t('loginPage:BUTTONS.JOIN')}</MyButton>
                </>
            ) : (
                <Box marginX={10} textAlign={'center'}>
                    <Text textColor={'titleColor'} fontSize={'md'}>
                        <Trans i18nKey={'loginPage:TEXTS:LOGIN_LINK_MESSAGE'}/>
                    </Text>
                    <Box mt={5} textAlign={'center'}>
                        <Text as={'u'} textColor={'titleColor'} fontSize={'sm'} fontWeight={'medium'}
                              onClick={() => setIsLoginFormVisible(true)}>{t('loginPage:BUTTONS:HAVE_CHECKIN_CODE')}</Text>
                    </Box>
                </Box>

            )}
            <Box mx={2}>
                <Text mb={7} fontSize={'sm'} display={'inline-flex'}
                      color={'captionColor'}>{t('loginPage:TEXTS.SUPPORT_MESSAGE')}</Text>
                <Text fontSize={'sm'} display={'inline-flex'} fontWeight={'bold'} color={'captionColor'} mx={1}
                > <a href={`tel:${supportPhoneNumber}`}>{t('loginPage:TEXTS.SUPPORT_LINK')}</a></Text>
                <MenuLanguageComponent/>
                <Text textColor={'captionColor'} fontSize={8} textAlign={'center'}>{'v' + versionObject.version}</Text>
            </Box>
        </MyPage>
    )
};


export default Login
