import React from 'react';
import {Center, Select} from '@chakra-ui/react';
import {SUPPORTED_LANGUAGES} from 'i18n/i18n.config';
import {useAppSelector} from 'store/store.config';
import {useDispatch} from 'react-redux';
import {SettingsActions} from 'store/settings/settings.action';

export const MenuLanguageComponent = () => {
    const dispatch = useDispatch();

    const currentLanguage = useAppSelector(state => state.settingsReducer.language);

    const setLanguage = (event: React.ChangeEvent<HTMLSelectElement>) =>{
        dispatch(SettingsActions.setLanguageSetting({language:event.target.value as SUPPORTED_LANGUAGES,overrideServer:true}));
    }

    return (
        <Center >
            <Select  textAlign={'end'} pl={5} variant={'unstyled'} size={'xs'} width={'auto'} value={currentLanguage as SUPPORTED_LANGUAGES} onChange={setLanguage}>
                {/* TODO: Create i18n common with all the language names so u can have name translation based on the current selected*/}
                <option value={SUPPORTED_LANGUAGES.IT}>Italian</option>
                <option value={SUPPORTED_LANGUAGES.EN}>English</option>
            </Select>
        </Center>
    );
}
