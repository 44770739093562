import {DatiFatturazioneModel} from 'models/prenotazione.model';


export interface SummingUpBillingForm extends DatiFatturazioneModel{

}

export const summingUpBillingFormDefault = (update?:Partial<SummingUpBillingForm>):SummingUpBillingForm=> ({
    nome: '',
    cognome: '',
    codiceFiscale: '',
    indirizzo: '', // indirizzo e numero civico
    citta: '',
    provincia: '',
    cap: '',
    partitaIva: '',
    sdi: '',
    ragioneSociale: '',
    nazione: '',
    personaFisica: true,
    ...update
})
