import {AxiosResponse} from 'axios';
import {StateModel} from 'models/state.model';
import {CityModel} from 'models/city.model';
import {appAxios} from 'service/axios.config';

const autocompleteStates = async ():Promise<StateModel[]> => {
    const response: AxiosResponse<StateModel[]> = await appAxios.get(`/webappguest/autocomplete/stati`);
    console.debug('Request [autocompleteStates] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error in autocompleteStates');
}

const autocompleteCities = async (city: string):Promise<CityModel[]> => {
    const response: AxiosResponse<CityModel[]> = await appAxios.get(`/webappguest/autocomplete/comuni/${city}`);
    console.debug('Request [autocompleteCities] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error in autocompleteCities');
}

const autocompleteDocument = async ():Promise<CityModel[]> => {
    const response: AxiosResponse<CityModel[]> = await appAxios.get(`/webappguest/autocomplete/documenti`);
    console.debug('Request [autocompleteDocument] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error in autocompleteDocument');
}

const autocompleteStateTranslation = async (stateCode: string):Promise<StateModel> =>{
    const response: AxiosResponse<StateModel> = await appAxios.get(`/webappguest/autocomplete/getTraduzioneStato/${stateCode}`);
    console.debug('Request [autocompleteStateTranslation] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error in autocompleteStateTranslation');
}


export const AutocompleteService = {
    autocompleteStates,
    autocompleteCities,
    autocompleteDocument,
    autocompleteStateTranslation
}
